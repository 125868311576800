import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import { useNavigate } from "react-router-dom";

interface BackButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  className?: string;
}

const BackButton: FC<BackButtonProps> = ({ children, className, ...props }) => {

    const navigate = useNavigate();

  return (
    <button
          onClick={() => navigate(-1)}
          className="absolute -ml-8 mt-1 text-2xl"
        >
            {children}
          <ArrowBackTwoToneIcon />
        </button>
  );
};

export default BackButton;
