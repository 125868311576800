import PageTitle from "../components/PageTitle";

export default function AnalyticsPage() {
    return (
        <section>
            <PageTitle>Analytics</PageTitle>
            <p className="text-xl">Analytics are coming soon. Please check back later, or better still -
                <a className="underline" target="_blank" href="https://discord.com/invite/THkkkGdZ2b" title="Join BeamXR on Discord">Join our discord</a> and we&apos;ll let you know when it's ready!
            </p>
        </section>
    )
}