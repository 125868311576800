import { NavLink, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMe } from "../context/MeContext";
import { useAuth0 } from "@auth0/auth0-react";
import useMeService from "../services/MeService";
import PageTitle from "../components/PageTitle";
import MeDetailForm from "../components/User/MeDetailForm";
import { UpdatedUser, UserSummary } from "../types/User";
import UserMembershipCard from "../components/User/UserMembershipCard";
import PositiveButton from "../components/PositiveButton";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import NewCompanyForm from "../components/Company/NewCompanyForm";
import { Company } from "../types/Company";
import { Membership } from "../types/Me";
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';

interface MembershipCardProps {
  membership: Membership;
}

function MembershipCard({ membership }: MembershipCardProps) {
  return <NavLink to={`/profile/company/${membership.company.id}`}
    title={`View ${membership.company.name}`}
    className="card p-4 flex flex-row justify-between items-center gap-2">
    <div className="flex flex-row gap-2 grow items-center justify-between">
      <p>{membership.company.name}</p>
      <ArrowForwardTwoToneIcon />
    </div>
  </NavLink>
}

export default function AccountPage() {
  const { me, refreshMe } = useMe();
  const { updateMe } = useMeService();
  const [newCompanyFormOpen, setNewCompanyFormOpen] = useState(false);

  const handleUserSaved = (user: UpdatedUser) => {
    refreshMe();
  }

  const handleNewCompanyRegistered = (newCompany: Company) => {
    refreshMe();
  }

  return (
    <>
      <NewCompanyForm open={newCompanyFormOpen} setOpen={setNewCompanyFormOpen} onSaved={handleNewCompanyRegistered} />
      <section className="flex flex-col gap-2 divide-stone-900">
        <div className="flex flex-row">
          <PageTitle>My profile</PageTitle>
        </div>
        <div className="grid grid-cols-1 2xl:grid-cols-3 gap-4">
          <div className="flex flex-col gap-2 col-span-2">
            <h2 className="text-2xl">Details</h2>
            <MeDetailForm user={me!} onSaved={handleUserSaved} />
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl">My companies</h2>
            {me!.memberships?.map((membership, idx) => (
              <MembershipCard
                key={idx}
                membership={membership} />
            ))}
            {me!.memberships?.length === 0 && <p>I have no memberships</p>}
            <PositiveButton
              onClick={() => { setNewCompanyFormOpen(true); }}
            >
              <AddTwoToneIcon className="h-8 w-8" />
              <span>
                Register a new company
              </span>
            </PositiveButton>
          </div>
        </div>
      </section>
    </>
  );
}
