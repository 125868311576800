import { useCallback, useEffect, useRef, useState } from "react";

import useRecordings from "../../hooks/ExperienceVideos/useRecordings";
import VideoFilterOptions from "./VideoFilterOptions";
import RecordingCard from "./RecordingCard";
import Pagination from "../Pagination";
import LoadingEllipsis from "../LoadingEllipsis";
import Ghost from "../Ghost";
import { ExperienceSummary } from "../../types/Experience";
import { SimpleUser } from "../../types/User";
import { CompanySummary } from "../../types/Company";
import { useUpdateQueryParams } from "../../hooks/ExperienceVideos/useUpdateVideoQueryParams";
import { VideoListContainer } from "./VideoListContainer";

const ITEMS_PER_PAGE = 12;
const ghostClasses = [
  "rounded h-48",
  "rounded h-48 hidden sm:block",
  "rounded h-48 hidden lg:block",
  "rounded h-48 hidden 2xl:block",
];

interface VideoListProps {
  companies: CompanySummary[] | [];
  initialCompany: CompanySummary | null;
  initialExperience: ExperienceSummary | null;
  initialUser: SimpleUser | null;
  initialPage: number;
}

export default function VideoList({
  companies,
  initialCompany = null,
  initialExperience = null,
  initialUser = null,
  initialPage = 1,
}: VideoListProps) {
  const [selectedCompany, setSelectedCompany] = useState<CompanySummary | null>(
    initialCompany
  );
  const [selectedExperience, setSelectedExperience] =
    useState<ExperienceSummary | null>(initialExperience);

  const [selectedUser, setSelectedUser] = useState<SimpleUser | null>(
    initialUser
  );
  const [page, setPage] = useState(initialPage ?? 1);

  const [experiences, setExperiences] = useState<ExperienceSummary[] | []>([]);
  const [users, setUsers] = useState<SimpleUser[]>([]);

  const isLoadingExperiences = useRef(false);
  const isLoadingUsers = useRef(false);

  const { recordings, isLoading, isError, fetchRecordings } = useRecordings({
    selectedCompany,
    selectedExperience,
    selectedUser,
    page,
    setPage,
  });

  // hook to update the query params as users selected search critieria
  useUpdateQueryParams(selectedCompany, selectedExperience, selectedUser, page);

  const isLoadingSearchCritieria =
    isLoadingExperiences.current || isLoadingUsers.current;

  const noExperiences =
    !isLoadingExperiences.current && experiences.length === 0;

  const noRecordings = recordings?.items.length === 0;

  // This useEffect will fire whenever any of the dependencies in the fetch recordings callback fires too.
  useEffect(() => {
    if (isLoadingExperiences.current || isLoadingUsers.current) {
      return;
    }

    if (page > 0) {
      fetchRecordings(ITEMS_PER_PAGE, users);
    }
  }, [fetchRecordings, page, users]);

  if (isError) {
    return (
      <p className="text-red-600 mt-3">
        Error retrieving recordings. Please try again.
      </p>
    );
  }

  return (
    <>
      <VideoListContainer>
        <VideoFilterOptions
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          selectedExperience={selectedExperience}
          setSelectedExperience={setSelectedExperience}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          companies={companies}
          experiences={experiences}
          setExperiences={setExperiences}
          isLoadingExperiences={isLoadingExperiences}
          isLoadingUsers={isLoadingUsers}
          users={users}
          setUsers={setUsers}
          isLoadingRecordings={isLoading}
        />

        {noExperiences ? (
          <p>No experiences exist for {selectedCompany?.name}.</p>
        ) : isLoadingSearchCritieria ? (
          <p>
            Loading search criteria for {selectedCompany?.name}
            <LoadingEllipsis />
          </p>
        ) : isLoading ? (
          <>
            <span>
              Retrieving recordings
              <LoadingEllipsis />
            </span>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6 mt-8">
              {ghostClasses.map((classes, index) => (
                <Ghost key={index} additionalClasses={classes} />
              ))}
            </div>
          </>
        ) : (
          <>
            {noRecordings ? (
              <p>No recordings available for your selected criteria.</p>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6 mt-8">
                {recordings?.items.map((recording) => (
                  <RecordingCard
                    recording={recording}
                    companyId={selectedCompany?.id}
                    key={recording.id}
                  />
                ))}
              </div>
            )}

            {recordings && recordings.totalPages > 1 && (
              <Pagination
                totalPages={Math.ceil(recordings.totalResults / ITEMS_PER_PAGE)}
                page={page}
                onPageChange={setPage}
                isDisabled={isLoading}
              />
            )}
          </>
        )}
      </VideoListContainer>
    </>
  );
}
