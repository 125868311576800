import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { ExperienceKey } from "../../types/ExperienceKey";
import CopyBox from "../CopyBox";
import CopySecretBox from "../CopySecretBox";
import { useState } from "react";
import SimpleAlert from "../SimpleAlert";
import useExperienceService from "../../services/ExperienceService";
import LoadingEllipsis from "../LoadingEllipsis";
import NegativeButton from "../NegativeButton";

interface ExperienceKeyCardProps {
  experienceKey: ExperienceKey;
  onDelete: (key: ExperienceKey) => void;
  deleteDisabled?: boolean;
  companyId: string;
  experienceId: string;
}

export default function ExperienceKeyCard({
  experienceKey,
  onDelete,
  deleteDisabled,
  companyId,
  experienceId
}: ExperienceKeyCardProps) {
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const {deleteExperienceKey} = useExperienceService();

  const deleteKey = () => {
    setDeleteAlertOpen(false);
    setIsDeleting(true);
    deleteExperienceKey(companyId, experienceId, experienceKey.key).then(() => {
      setDeleteAlertOpen(false);
      onDelete(experienceKey);
    }).catch((error) => {
      alert("An error occurred while deleting the key.");
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  return (
    <>
      <div className="card p-4 flex flex-col gap-2">
        <h3>Key</h3>
        <CopyBox
          text={experienceKey.key}
          textBoxClasses="rounded p-4 bg-cyan-700/25 border-cyan-800 border border-1"
        />
        <h3>Secret</h3>
        <CopySecretBox
          text={experienceKey.secret}
          textBoxClasses="rounded p-4 bg-cyan-700/25 border-cyan-800 border border-1"
        />
        {!deleteDisabled && (
          
            <NegativeButton
              className="flex flex-row gap-2 text-center justify-center"
              disabled={isDeleting}
              onClick={() => setDeleteAlertOpen(true)}
            >
              <DeleteTwoToneIcon className="h-8 w-8" />
              {isDeleting ? <span>Deleting key<LoadingEllipsis /></span> : <span>Delete key</span>}
            </NegativeButton>
        )}
      </div>
      <SimpleAlert
        open={deleteAlertOpen}
        setOpen={setDeleteAlertOpen}
        title={`Are you sure you want to delete ${experienceKey.key}?`}
        message="This action cannot be undone and any experience using the key will stop working."
        confirmText={`Delete ${experienceKey.key}?`}
        onConfirm={deleteKey}
      />
    </>
  );
}
