import { useState, useEffect, useCallback, MutableRefObject } from "react";
import { VideoSize } from "../types/Video";

export function useVideoSize(
  videoEl: MutableRefObject<HTMLVideoElement | null>
) {
  const [videoSize, setVideoSize] = useState<VideoSize>({
    width: 0,
    height: 0,
  });
  const [parentWidth, setParentWidth] = useState<number>(0);

  const calculateVideoSize = useCallback(() => {
    const videoElement = videoEl.current;
    if (!videoElement) return { width: 0, height: 0 };

    const intrinsicWidth = videoElement.videoWidth;
    const intrinsicHeight = videoElement.videoHeight;
    const videoRect = videoElement.getBoundingClientRect();
    const parentRect = videoElement.parentElement?.getBoundingClientRect();

    let displayedWidth: number, displayedHeight: number;
    if (videoRect.width / videoRect.height > intrinsicWidth / intrinsicHeight) {
      displayedHeight = videoRect.height;
      displayedWidth = (intrinsicWidth / intrinsicHeight) * displayedHeight;
    } else {
      displayedWidth = videoRect.width;
      displayedHeight = (intrinsicHeight / intrinsicWidth) * displayedWidth;
    }

    return {
      width: displayedWidth,
      height: displayedHeight,
      parentWidth: parentRect?.width || 0,
    };
  }, [videoEl]);

  // Expose this function to allow manual size updates
  const updateVideoSize = useCallback(() => {
    const videoElement = videoEl.current;
    if (!videoElement) return;

    requestAnimationFrame(() => {
      const { width, height, parentWidth } = calculateVideoSize();
      setVideoSize({ width, height });
      setParentWidth(parentWidth ?? 0);
    });
  }, [calculateVideoSize, videoEl]);

  useEffect(() => {
    const videoElement = videoEl.current;
    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", updateVideoSize);
      window.addEventListener("resize", updateVideoSize);

      return () => {
        videoElement.removeEventListener("loadedmetadata", updateVideoSize);
        window.removeEventListener("resize", updateVideoSize);
      };
    }
  }, [videoEl, updateVideoSize]);

  // Return both videoSize and the updateVideoSize function
  return { videoSize, parentWidth, updateVideoSize };
}
