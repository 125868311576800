import YouTube from "react-youtube";
import PageTitle from "../components/PageTitle";
import { useState } from "react";
import LoadingEllipsis from "../components/LoadingEllipsis";

export default function DocumentationPage() {

    const youTubeVideoUrl = "https://youtu.be/zW3qJB2m8rE";

    const [videoReady, setVideoReady] = useState(false);

    return (
        <section className="flex flex-col gap-4">
            <PageTitle>Documentation</PageTitle>
            <p className="text-xl">Documentation is coming soon. Please check back later, or better still -
                <a className="underline" target="_blank" href="https://discord.com/invite/THkkkGdZ2b" title="Join BeamXR on Discord">Join our discord</a> and we&apos;ll let you know when it's ready!
            </p>
            <p>In the meantime, here is the latest video to get you started using the SDK:</p>
            <YouTube loading="lazy" videoId="zW3qJB2m8rE" onReady={() => { setVideoReady(true); }} />
            {!videoReady && <p>Loading video<LoadingEllipsis /></p>}
        </section>
    )
}