import { FormEvent, useState } from "react";
import { Experience } from "../../types/Experience";
import useExperienceService from "../../services/ExperienceService";
import { useMe } from "../../context/MeContext";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import PositiveButton from "../PositiveButton";
import NegativeButton from "../NegativeButton";
import { DialogContent } from "@mui/material";

interface BlockExperienceDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onComplete?: (experience: Experience) => void;
  onCancel?: () => void;
  experience: Experience;
}

export default function BlockExperienceDialog({
  onComplete,
  open,
  setOpen,
  onCancel,
  experience,
}: BlockExperienceDialogProps) {
  const { me } = useMe();

  const [formData, setFormData] = useState({
    reason: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const { blockExperience } = useExperienceService();

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};

    if (formData.reason.trim() === "") {
      newErrors.reason = "Reason is required";
      isValid = false;
    }

    setValidationErrors(newErrors);

    return isValid;
  };

  const submitBlockExperience = async (e: FormEvent) => {
    e.preventDefault();

    setSubmitted(true);

    if (!validateForm()) return;

    setIsSaving(true);

    blockExperience(experience.id, formData.reason)
      .then(() => {
        onComplete && onComplete({ ...experience, isBlocked: true, blockedReason: formData.reason });
        setFormData({
          reason: "",
        });

        if (!onComplete) {
          setOpen(false);
        }
      })
      .catch((error) => {
        console.error("Error blocking experience:", error);
        const newErrors: any = {
          reason:
            "Failed to block experience. Please try again. If the problem persists, please contact support.",
        };
        setValidationErrors(newErrors);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validStyle = (fieldName: string) => {
    // Find the validationErrors key with the fieldName.
    let valid = Object.keys(validationErrors).indexOf(fieldName) === -1;

    return valid ? "" : "border-red-800";
  };

  const validationError = (fieldName: string): null | string => {
    return validationErrors[fieldName] || null;
  };

  const isFormValid = submitted && Object.keys(validationErrors).length === 0;

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-slate-900 text-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <DialogTitle
              as="h3"
              className="text-base leading-6 text-white pb-4"
            >
              <h3>Block experience</h3>
            </DialogTitle>
            <DialogContent className="flex flex-col gap-2">
            <p>
                Blocking an experience will prevent new sessions from being
                created. This can be unblocked later by an administrator.
              </p>
              <form
                onSubmit={submitBlockExperience}
                className="p-4 card flex flex-col form grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4"
              >
                <div className="sm:col-span-4">
                  <label htmlFor="reason" className="block leading-6">
                    Reason:
                  </label>
                  <div className="mt-2">
                    <input
                      disabled={isSaving}
                      type="text"
                      name="reason"
                      placeholder="Reason for blocking experience *"
                      value={formData.reason}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                        "reason"
                      )}`}
                    />
                  </div>
                  {validationError("reason") && (
                    <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                      {validationError("reason")}
                    </div>
                  )}
                </div>
                {/* Submit Button */}
                <div className="sm:col-span-4 flex items-center gap-2 justify-end">
                  <NegativeButton type="submit" disabled={isSaving}>
                    {isSaving ? "Blocking experience..." : "Block experience"}
                  </NegativeButton>
                </div>
              </form>
            </DialogContent>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
