import { HttpError } from "../types/Error";
import { Experience } from "../types/Experience";
import useApiClient from "./ApiClient";
import { PagedResults } from "../types/PagedResults";
import { ExperienceKey } from "../types/ExperienceKey";
import { useCallback } from "react";
import {
  GetExperienceRecordingsParameters,
  Recording,
} from "../types/Recording";

const useExperienceService = () => {
  const { get, post, put, delete: del } = useApiClient();

  const createNewExperienceKey = async (
    companyId: string,
    experienceId: string
  ): Promise<ExperienceKey> => {
    try {
      const result = await post<ExperienceKey>(
        `/company/${companyId}/experience/${experienceId}/keys`,
        { companyId, experienceId }
      );
      // Check if the response is an error.
      if ((result as HttpError).errorCode) {
        throw (result as HttpError).description;
      }

      return result as ExperienceKey;
    } catch (error) {
      console.error("Error creating experience key:", error);
      throw error;
    }
  };

  const deleteExperienceKey = async (
    companyId: string,
    experienceId: string,
    key: string
  ): Promise<boolean> => {
    try {
      // Url encode the key.
      key = encodeURIComponent(key);

      const result = await del(
        `/company/${companyId}/experience/${experienceId}/keys/${key}`
      );
      // Check if the response is an error.
      if ((result as HttpError).errorCode) {
        throw (result as HttpError).description;
      }

      return true;
    } catch (error) {
      console.error("Error deleting experience key:", error);
      throw error;
    }
  };

  const getExperienceKeys = async (
    companyId: string,
    experienceId: string
  ): Promise<ExperienceKey[]> => {
    try {
      const item = await get<ExperienceKey[]>(
        `/company/${companyId}/experience/${experienceId}/keys`
      );
      // Check if the response is an error.
      if ((item as HttpError).errorCode) {
        throw (item as HttpError).description;
      }

      return item as ExperienceKey[];
    } catch (error) {
      console.error("Error getting experience:", error);
      throw error;
    }
  };

  const deleteExperience = async (
    companyId: string,
    experienceId: string
  ): Promise<boolean> => {
    try {
      var result = await del(
        `/company/${companyId}/experience/${experienceId}`
      );
      // Check if the response is an error.
      if ((result as HttpError).errorCode) {
        throw (result as HttpError).description;
      }

      return true;
    } catch (error) {
      console.error("Error deleting experience:", error);
      throw error;
    }
  };

  const getExperience = async (
    companyId: string,
    experienceId: string
  ): Promise<Experience> => {
    try {
      const item = await get<Experience>(
        `/company/${companyId}/experience/${experienceId}`
      );
      // Check if the response is an error.
      if ((item as HttpError).errorCode) {
        throw (item as HttpError).description;
      }

      return item as Experience;
    } catch (error) {
      console.error("Error getting experience:", error);
      throw error;
    }
  };

  const getExperiencesForCompany = useCallback(
    async (companyId: string): Promise<Experience[]> => {
      try {
        const items = await get<Experience[]>(
          `/company/${companyId}/experience`
        );
        if ((items as HttpError).errorCode) {
          throw (items as HttpError).description;
        }
        return items as Experience[];
      } catch (error) {
        console.error("Error getting experiences:", error);
        throw error;
      }
    },
    [get]
  );

  const updateExperience = async (
    experience: Experience
  ): Promise<Experience> => {
    try {
      const result = await put<Experience>(
        `/company/${experience.companyId}/experience/${experience.id}`,
        experience
      );
      // Check if the response is an error.
      if ((result as HttpError).errorCode) {
        throw (result as HttpError).description;
      }

      return result as Experience;
    } catch (error) {
      console.error("Error updating experience:", error);
      throw error;
    }
  };

  const createExperience = async (
    companyId: string,
    name: string
  ): Promise<Experience> => {
    try {
      const result = await post<Experience>(
        `/company/${companyId}/experience/register`,
        { name }
      );
      // Check if the response is an error.
      if ((result as HttpError).errorCode) {
        throw (result as HttpError).description;
      }

      return result as Experience;
    } catch (error) {
      console.error("Error creating experience:", error);
      throw error;
    }
  };

  const searchExperiences = async (
    searchTerm?: string,
    page?: number,
    orderBy?: string,
    orderDirection?: string
  ): Promise<PagedResults<Experience>> => {
    try {
      const postData = {
        searchTerm,
        page: page ?? 1,
        itemsPerPage: 10,
        orderBy: orderBy ?? "name",
        orderDirection: orderDirection ?? "asc",
      };

      const result = await post<PagedResults<Experience>>(
        `/admin/experience/search`,
        postData
      );

      // Check if the response is an error.
      if ((result as HttpError).errorCode) {
        throw (result as HttpError).description;
      }

      return result as PagedResults<Experience>;
    } catch (error) {
      console.error("Error searching experiences:", error);
      throw error;
    }
  };

  const blockExperience = async (
    experienceId: string,
    reason: string
  ): Promise<boolean> => {
    try {
      const result = await post<boolean>(
        `/admin/experience/${experienceId}/block`,
        { reason }
      );
      // Check if the response is an error.
      if ((result as HttpError).errorCode) {
        throw (result as HttpError).description;
      }

      return true;
    } catch (error) {
      console.error("Error blocking experience:", error);
      throw error;
    }
  };

  const unblockExperience = async (experienceId: string): Promise<boolean> => {
    try {
      const result = await del(`/admin/experience/${experienceId}/Block`);
      // Check if the response is an error.
      if ((result as HttpError).errorCode) {
        throw (result as HttpError).description;
      }

      return true;
    } catch (error) {
      console.error("Error unblocking experience:", error);
      throw error;
    }
  };

  const getRecordingsByExperience = useCallback(
    async (
      companyId: string,
      experienceId: string,
      params?: GetExperienceRecordingsParameters
    ): Promise<PagedResults<Recording>> => {
      try {
        // Construct query string based on optional params
        const queryParams = new URLSearchParams();

        if (params?.itemsPerPage) {
          queryParams.append("itemsPerPage", params.itemsPerPage.toString());
        }
        if (params?.page) {
          queryParams.append("page", params.page.toString());
        }
        if (params?.userId) {
          queryParams.append("userId", params.userId);
        }

        const queryString = queryParams.toString()
          ? `?${queryParams.toString()}`
          : "";

        // Fetch the paginated result, not just an array of Recording
        const results = await get<PagedResults<Recording>>(
          `/company/${companyId}/experience/${experienceId}/recordings${queryString}`
        );

        if ("errorCode" in results) {
          throw new Error(results.description);
        }

        return results;
      } catch (error) {
        console.error("Error getting recordings:", error);
        throw error;
      }
    },
    [get]
  );

  const getRecordingByExperience = useCallback(
    async (
      companyId: string,
      experienceId: string,
      recordingId: string
    ): Promise<Recording> => {
      try {
        // Fetch the paginated result, not just an array of Recording
        const result = await get<Recording>(
          `/company/${companyId}/experience/${experienceId}/recordings/${recordingId}`
        );

        if ("errorCode" in result) {
          throw new Error(result.description);
        }

        return result;
      } catch (error) {
        console.error("Error getting recordings:", error);
        throw error;
      }
    },
    [get]
  );

  return {
    getExperience,
    getExperienceKeys,
    getExperiencesForCompany,
    updateExperience,
    createExperience,
    deleteExperience,
    createNewExperienceKey,
    deleteExperienceKey,
    searchExperiences,
    blockExperience,
    unblockExperience,
    getRecordingsByExperience,
    getRecordingByExperience,
  };
};

export default useExperienceService;
