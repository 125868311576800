import { ReactNode } from "react";

interface PageTitleProps {
    children: ReactNode;
    className?: string;
}

export default function PageTitle({ children, className }: PageTitleProps) {
    return (
        <h1 className={`text-base sm:text-2xl xl:text-4xl ${className}`}>{children}</h1>
    );
}