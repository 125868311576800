import React from "react";
import { VideoSize } from "../types/Video";

interface VideoToggleButtonProps {
  isToggledOn: boolean;
  toggleAction: () => void;
  iconOn: React.FC<React.SVGProps<SVGSVGElement>>;
  iconOff: React.FC<React.SVGProps<SVGSVGElement>>;
  labelOn: string;
  labelOff: string;
  videoSize: VideoSize;
  parentWidth: number;
  buttonStyleOn?: string;
  buttonStyleOff?: string;
  horizontalPosition?: "left" | "right";
  verticalPosition?: "top" | "bottom";
  verticalOffset?: string;
}

function VideoToggleButton({
  isToggledOn,
  toggleAction,
  iconOn: IconOn,
  iconOff: IconOff,
  labelOn,
  labelOff,
  videoSize,
  parentWidth,
  buttonStyleOn = "bg-white text-black",
  buttonStyleOff = "bg-white text-black",
  horizontalPosition = "left",
  verticalPosition = "top",
  verticalOffset = "6px",
}: VideoToggleButtonProps) {
  if (!videoSize.width || !videoSize.height || !parentWidth) {
    return null;
  }

  // Dynamically calculate the `left` or `right` style based on `horizontalPosition`
  const horizontalStyle =
    horizontalPosition === "left"
      ? {
          left: `${
            (parentWidth - videoSize.width) / 2 + videoSize.width * 0.01
          }px`,
        }
      : {
          right: `${
            (parentWidth - videoSize.width) / 2 + videoSize.width * 0.01
          }px`,
        };

  // Dynamically calculate the `top` or `bottom` style based on `verticalPosition`
  const verticalStyle =
    verticalPosition === "top"
      ? { top: verticalOffset }
      : { bottom: verticalOffset };

  return (
    <button
      className={`absolute px-4 py-2 rounded-full flex items-center space-x-2 h-12 z-20 cursor-pointer ${
        isToggledOn ? buttonStyleOn : buttonStyleOff
      }`}
      onClick={toggleAction}
      style={{
        ...horizontalStyle,
        ...verticalStyle,
      }}
    >
      {isToggledOn ? (
        <IconOn className="w-6 h-6 text-black" />
      ) : (
        <IconOff className="w-6 h-6 text-black" />
      )}
      <span className="hidden lg:inline">
        {isToggledOn ? labelOn : labelOff}
      </span>
    </button>
  );
}

export default VideoToggleButton;
