import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import useExperienceService from "../../services/ExperienceService";
import useCompanyService from "../../services/CompanyService";
import { CompanySummary } from "../../types/Company";
import { ExperienceSummary } from "../../types/Experience";
import { useCompanies } from "../../hooks/useCompanies";
import { SimpleUser } from "../../types/User";
import PageTitle from "../PageTitle";
import LoadingEllipsis from "../LoadingEllipsis";
import VideoList from "./VideoList";

interface VideoPageContainerProps {
  pageTitle: string;
}

export default function VideoPageContainer({
  pageTitle,
}: VideoPageContainerProps) {
  const location = useLocation();
  const { getExperiencesForCompany } = useExperienceService();
  const { getCompanyUsers } = useCompanyService();

  const [isLoadingExperience, setIsLoadingExperience] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const [selectedCompany, setSelectedCompany] = useState<CompanySummary | null>(
    null
  );
  const [selectedExperience, setSelectedExperience] =
    useState<ExperienceSummary | null>(null);
  const [selectedUser, setSelectedUser] = useState<SimpleUser | null>(null);
  const [page, setPage] = useState(1);

  const { companies, isLoading: isLoadingCompanies } = useCompanies();

  const getExperienceForCompany = useCallback(
    async (companyId: string, experienceId: string | null) => {
      setIsLoadingExperience(true);
      try {
        const experiences = await getExperiencesForCompany(companyId);
        // Find the selected experience or set the first one if none selected
        const experience = experiences.find((exp) => exp.id === experienceId);
        return experience ?? null;
      } catch (error) {
        console.error("Error fetching experiences:", error);
        return null;
      } finally {
        setIsLoadingExperience(false);
      }
    },
    [getExperiencesForCompany]
  );

  const getUserForCompany = useCallback(
    async (companyId: string, userId: string | null) => {
      setIsLoadingUser(true);
      try {
        const companyUsers = await getCompanyUsers(companyId);
        const simpleUsers = companyUsers.map((companyUser) => ({
          id: companyUser.id,
          name:
            companyUser.givenName || companyUser.familyName
              ? `${companyUser.givenName || ""} ${
                  companyUser.familyName || ""
                }`.trim()
              : "Name not provided",
          companyId: companyId,
        }));
        // Return the selected user or null if not found
        const selectedUser = simpleUsers.find((user) => user.id === userId);
        return selectedUser ?? null;
      } catch (error) {
        console.error("Error fetching users:", error);
        return null;
      } finally {
        setIsLoadingUser(false);
      }
    },
    [getCompanyUsers]
  );

  const fetchInitialData = useCallback(async () => {
    if (
      !companies ||
      !isInitialLoading ||
      isLoadingCompanies ||
      isLoadingExperience ||
      isLoadingUser
    )
      return;

    const searchParams = new URLSearchParams(location.search);

    const companyId = searchParams.get("company");
    const experienceId = searchParams.get("experience");
    const userId = searchParams.get("user");
    const pageParam = searchParams.get("page");

    if (companyId) {
      const company = companies.find((c) => c.id === companyId);
      setSelectedCompany(company || companies[0]);
    } else {
      setSelectedCompany(companies[0]);
    }

    if (companyId && experienceId) {
      const experience = await getExperienceForCompany(companyId, experienceId);
      setSelectedExperience(experience);
    } else {
      setSelectedExperience(null);
    }

    if (companyId && userId) {
      const user = await getUserForCompany(companyId, userId);
      setSelectedUser(user);
    } else {
      setSelectedUser(null);
    }

    setPage(pageParam ? Number(pageParam) : 1);
    setIsInitialLoading(false);
  }, [
    companies,
    getExperienceForCompany,
    getUserForCompany,
    isInitialLoading,
    isLoadingCompanies,
    isLoadingExperience,
    isLoadingUser,
    location.search,
  ]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  return (
    <section>
      <PageTitle>{pageTitle}</PageTitle>
      {isInitialLoading ||
      isLoadingCompanies ||
      isLoadingExperience ||
      isLoadingUser ? (
        <p>
          Loading <LoadingEllipsis />
        </p>
      ) : (
        <VideoList
          companies={companies}
          initialCompany={selectedCompany}
          initialExperience={selectedExperience}
          initialUser={selectedUser}
          initialPage={page}
        />
      )}
    </section>
  );
}
