import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

export interface FilterableSelectProps<T> {
  items: T[];
  selected: T | null;
  setSelected: (item: T | null) => void;
  renderLabel: (item: T) => string;
  headerText: string;
  allowEmpty?: boolean;
  emptyLabel?: string;
  disabled?: boolean;
}

export default function FilterableSelect<T>({
  items,
  headerText,
  selected,
  setSelected,
  renderLabel,
  allowEmpty = false,
  emptyLabel = "None",
  disabled = false,
}: FilterableSelectProps<T>) {
  const [query, setQuery] = useState("");

  const filteredItems =
    query === ""
      ? items
      : items.filter((item) =>
          renderLabel(item).toLowerCase().includes(query.toLowerCase())
        );

  return (
    <Combobox
      as="div"
      value={selected}
      onChange={(item) => {
        setQuery("");
        setSelected(item);
      }}
      disabled={disabled}
    >
      <Label className="block text-lg leading-6 text-white">{headerText}</Label>
      <div className="relative mt-2">
        <ComboboxInput
          className={`w-full rounded-md py-1.5 pl-3 pr-10 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-600 sm:text-sm sm:leading-6 ${
            disabled
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-white cursor-default"
          }`}
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery("")}
          displayValue={(item: T) => (item ? renderLabel(item) : "")}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon
            className="h-5 w-5 text-cyan-950"
            aria-hidden="true"
          />
        </ComboboxButton>

        {filteredItems.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {/* Conditionally render empty option if allowed */}
            {allowEmpty && (
              <ComboboxOption
                key="empty"
                value={null}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-black data-[focus]:bg-cyan-600 data-[focus]:text-white"
              >
                <span className="block truncate">{emptyLabel}</span>
              </ComboboxOption>
            )}

            {filteredItems.map((item) => (
              <ComboboxOption
                key={(item as any).id}
                value={item}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-black data-[focus]:bg-cyan-600 data-[focus]:text-white"
              >
                <span className="block truncate group-data-[selected]:font-semibold">
                  {renderLabel(item)}
                </span>
                <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-cyan-600 group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}
