import { FormEvent, useState } from "react";
import { UpdatedUser, User } from "../../types/User";
import useUrlUtilities from "../../utilities/UrlUtilities";
import { useNavigate } from "react-router-dom";
import PositiveButton from "../PositiveButton";
import useMeService from "../../services/MeService";
import { Me } from "../../types/Me";
import { useMe } from "../../context/MeContext";

interface MeDetailFormProps {
    user: Me;
    onSaved?: (user: UpdatedUser) => void;
}

export default function MeDetailForm({
    user,
    onSaved,
  }: MeDetailFormProps) {
    const [formData, setFormData] = useState({
      email: user.email,
      givenName: user.givenName ?? "",
      nickname: user.nickname ?? "",
      familyName: user.familyName ?? ""
    });
  
    const { isValidUrl } = useUrlUtilities();
    const navigate = useNavigate();
    const {me} = useMe();
  
    const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isClean, setIsClean] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{
      [key: string]: string;
    }>({});
  
    const { updateMe } = useMeService();
  
    const validateForm = () => {
      let isValid = true;
      const newErrors: any = {};
      
      if (!formData.familyName) {
        newErrors.name = "Family name is required";
        isValid = false;
      }
  
      setValidationErrors(newErrors);
  
      return isValid;
    };
  
    const saveCompany = async (e: FormEvent) => {
      e.preventDefault();
  
      setSubmitted(true);
  
      if (!validateForm()) return;
  
      setIsSaving(true);
  
      try {
        // Save the company
        const itemToSave: UpdatedUser = {
          id: me!.id,
          ...formData
        };
  
        const newUser = await updateMe(itemToSave);
  
        if (onSaved) {
          onSaved(newUser);
        }
  
        setIsClean(true);
      } catch (error) {
        const newErrors: any = {
          name: "Failed to save user. Please try again. If the problem persists, please contact support.",
        };
        setValidationErrors(newErrors);
      } finally {
        setIsSaving(false);
      }
    };
  
    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
  
      setIsClean(false);
  
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const validStyle = (fieldName: string) => {
      // Find the validationErrors key with the fieldName.
      let valid = Object.keys(validationErrors).indexOf(fieldName) === -1;
  
      return valid ? "" : "border-red-800";
    };
  
    const validationError = (fieldName: string): null | string => {
      return validationErrors[fieldName] || null;
    };
  
    const isFormValid = submitted && Object.keys(validationErrors).length === 0;
  
    return (
      <>
        <form
          onSubmit={saveCompany}
          className="card p-8 flex flex-col form grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4"
        >
          <div className="sm:col-span-4">
            <label htmlFor="givenName" className="block leading-6">
              Given name:
            </label>
            <div className="mt-2">
              <input
                disabled={isSaving}
                type="text"
                name="givenName"
                placeholder="Given name"
                value={formData.givenName}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                  "givenName"
                )}`}
              />
            </div>
            {validationError("givenName") && (
              <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                {validationError("givenName")}
              </div>
            )}
          </div>

          <div className="sm:col-span-4">
            <label htmlFor="nickname" className="block leading-6">
              Nickname:
            </label>
            <div className="mt-2">
              <input
                disabled={isSaving}
                type="text"
                name="nickname"
                placeholder="Nickname"
                value={formData.nickname}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                  "nickname"
                )}`}
              />
            </div>
            {validationError("nickname") && (
              <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                {validationError("nickname")}
              </div>
            )}
          </div>
  
          <div className="sm:col-span-4">
            <label htmlFor="familyName" className="block leading-6">
              Family name:
            </label>
            <div className="mt-2">
              <input
                disabled={isSaving}
                type="text"
                name="familyName"
                placeholder="Family name"
                value={formData.familyName}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                  "familyName"
                )}`}
              />
            </div>
            {validationError("familyName") && (
              <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                {validationError("familyName")}
              </div>
            )}
          </div>
  
          {/* Submit Button */}
          <div className="sm:col-span-4 flex items-center gap-2 justify-end">
            <PositiveButton
              type="submit"
              disabled={isSaving || isClean}
            >
              {isSaving ? "Saving..." : isClean ? "Saved" : "Save changes"}
            </PositiveButton>
          </div>
        </form>
      </>
    );
  }