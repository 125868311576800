import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export interface SelectMenuProps<T> {
  items: T[];
  selected: T | null;
  setSelected: (item: T | null) => void;
  renderLabel: (item: T) => string;
  headerText: string;
  allowEmpty?: boolean;
  emptyLabel?: string;
  disabled?: boolean;
}

export default function SelectMenu<T>({
  items,
  headerText,
  selected,
  setSelected,
  renderLabel,
  allowEmpty = false,
  emptyLabel = "None",
  disabled = false,
}: SelectMenuProps<T>) {
  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      <Label className="block text-lg leading-6 text-white">{headerText}</Label>
      <div className="relative mt-2">
        <ListboxButton
          className={`relative w-full rounded-md  py-1.5 pl-3 pr-10 text-left text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-600 sm:text-sm sm:leading-6 ${
            disabled
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-white cursor-default"
          }`}
          disabled={disabled}
        >
          <span className="block truncate">
            {selected ? renderLabel(selected) : emptyLabel}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-cyan-950"
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {/* Conditionally render empty option if allowed */}
          {allowEmpty && (
            <ListboxOption
              key="empty"
              value={null}
              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-black data-[focus]:bg-cyan-600 data-[focus]:text-white"
            >
              <span className="block truncate font-normal">{emptyLabel}</span>
            </ListboxOption>
          )}

          {/* Map over the items */}
          {items.map((item) => (
            <ListboxOption
              key={(item as any).id}
              value={item}
              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-black data-[focus]:bg-cyan-600 data-[focus]:text-white"
            >
              <span className="block truncate font-normal group-data-[selected]:font-semibold">
                {renderLabel(item)}
              </span>

              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-cyan-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                <CheckIcon aria-hidden="true" className="h-5 w-5" />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
