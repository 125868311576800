import NeutralButton from "./NeutralButton";

interface GenericErrorProps {
    errorMessage: string;
}

export default function GenericError({errorMessage} :GenericErrorProps) {

    const refreshPage = () => {
        window.location.reload();
    }

    return (
        <div className="flex items-center justify-center h-screen bg-gray-30">
            <div className="flex flex-col items-center space-y-4">
                <img src="/img/logo-dev.svg" alt="BeamXR Developer Portal" />
                <h1 className="text-lg text-white">
                    {errorMessage}                    
                </h1>
                <p className="text-white">Please try reloading the page. If the problem persists then please contact BeamXR support.</p>
                <NeutralButton className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" onClick={refreshPage}>Reload</NeutralButton>
            </div>
        </div>
    );
}