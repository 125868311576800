import PositiveButton from "./PositiveButton";

interface PaginationProps {
  totalPages: number;
  page: number;
  isDisabled: boolean;
  onPageChange: (newPage: number) => void;
}
function Pagination<T>({
  totalPages,
  page,
  isDisabled,
  onPageChange,
}: PaginationProps) {
  if (totalPages <= 1) return null;

  return (
    <section className="flex justify-between items-center mt-4">
      <PositiveButton
        onClick={() => onPageChange(page - 1)}
        disabled={page === 1 || isDisabled}
      >
        Previous
      </PositiveButton>
      <span>
        Page {page} of {totalPages}
      </span>
      <PositiveButton
        onClick={() => onPageChange(page + 1)}
        disabled={page === totalPages || isDisabled}
      >
        Next
      </PositiveButton>
    </section>
  );
}

export default Pagination;
