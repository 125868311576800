'use client'

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import NegativeButton from './NegativeButton'
import NeutralButton from './NeutralButton'

interface SimpleAlertProps {
    open: boolean
    setOpen: (open: boolean) => void
    onConfirm?: () => void
    onCancel?: () => void
    title?: string
    message?: string
    confirmText?: string
    cancelText?: string
}

export default function SimpleAlert(
    {
        open, 
        setOpen, 
        onConfirm, 
        onCancel,
        title, 
        message, 
        confirmText, 
        cancelText
    }: SimpleAlertProps) {
  
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-slate-900 text-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle as="h3" className="text-base leading-6 text-white">
                  {title || 'Are you sure?'}
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm text-gray-100">
                    {message || 'This action cannot be undone.'}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-2">
              <NegativeButton
                type="button"
                onClick={async () => {
                    if (onConfirm) {
                      await onConfirm();
                    } else {
                      setOpen(false);
                    }
                }}
                className="px-3 py-2 hover:bg-red-500 sm:ml-3 ml-3 sm:w-auto"
              >
                {confirmText || 'Confirm'}
              </NegativeButton>
              <NeutralButton
                type="button"
                data-autofocus
                onClick={() => {
                    if (onCancel) onCancel();
                    setOpen(false);
                }}
              >
                {cancelText || 'Cancel'}
              </NeutralButton>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
