import { useEffect, useState } from "react";

import useCompanyService from "../../services/CompanyService";
import useExperienceService from "../../services/ExperienceService";
import { CompanySummary } from "../../types/Company";
import { ExperienceSummary } from "../../types/Experience";
import { SimpleUser } from "../../types/User";
import SelectMenu from "../SelectMenu";
import FilterableSelect from "../FilterableSelect";

interface VideoFilterOptionsProps {
  selectedCompany: CompanySummary | null;
  setSelectedCompany: (company: CompanySummary | null) => void;
  selectedExperience: ExperienceSummary | null;
  setSelectedExperience: (experience: ExperienceSummary | null) => void;
  selectedUser: SimpleUser | null;
  setSelectedUser: (user: SimpleUser | null) => void;
  companies: CompanySummary[] | [];
  experiences: ExperienceSummary[] | [];
  setExperiences: (experiences: ExperienceSummary[] | []) => void;
  isLoadingExperiences: React.MutableRefObject<boolean>;
  isLoadingUsers: React.MutableRefObject<boolean>;
  users: SimpleUser[];
  setUsers: (users: SimpleUser[]) => void;
  isLoadingRecordings: boolean;
}

export default function VideoFilterOptions({
  selectedCompany,
  setSelectedCompany,
  selectedExperience,
  setSelectedExperience,
  selectedUser,
  setSelectedUser,
  companies,
  experiences,
  setExperiences,
  isLoadingExperiences,
  isLoadingUsers,
  users,
  setUsers,
  isLoadingRecordings,
}: VideoFilterOptionsProps) {
  const { getExperiencesForCompany } = useExperienceService();
  const { getCompanyUsers } = useCompanyService();

  const [experiencesError, setExperiencesError] = useState<string | null>(null);
  const [usersError, setUsersError] = useState<string | null>(null);

  // Experiences useEffect
  useEffect(() => {
    if (
      !selectedCompany ||
      (selectedExperience?.companyId === selectedCompany?.id &&
        experiences.length > 0) ||
      isLoadingExperiences.current === true
    ) {
      return;
    }

    isLoadingExperiences.current = true;
    setExperiences([]);
    setExperiencesError(null);

    const loadExperiences = async () => {
      try {
        const experiences = await getExperiencesForCompany(selectedCompany.id);

        // Sort the experiences array by 'name' property
        const sortedExperiences = experiences.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        setExperiences(sortedExperiences);

        // Find the selected experience or set the first one if none selected
        const experience = sortedExperiences.find(
          (exp) => exp.id === selectedExperience?.id
        );

        if (experience) {
          setSelectedExperience(experience);
        } else {
          // If no matching experience found, set the first one and reset the page to 1
          setSelectedExperience(
            sortedExperiences.length > 0 ? sortedExperiences[0] : null
          );
        }
      } catch (error) {
        console.error("experiences error", error);
        setExperiencesError("Failed to load experiences");
      } finally {
        isLoadingExperiences.current = false;
      }
    };

    loadExperiences();
  }, [
    selectedCompany,
    getExperiencesForCompany,
    setSelectedExperience,
    isLoadingExperiences,
    selectedExperience?.companyId,
    experiences.length,
    setExperiences,
    selectedExperience,
  ]);

  // users useEffect
  useEffect(() => {
    if (
      !selectedCompany ||
      (users.length > 0 && users[0].companyId === selectedCompany.id) ||
      isLoadingUsers.current
    ) {
      return;
    }

    isLoadingUsers.current = true;
    setUsersError(null);

    const loadUsers = async () => {
      try {
        const companyUsers = await getCompanyUsers(selectedCompany.id);
        const simpleUsers = companyUsers
          .map((companyUser) => ({
            id: companyUser.id,
            name:
              companyUser.givenName || companyUser.familyName
                ? `${companyUser.givenName || ""} ${
                    companyUser.familyName || ""
                  }`.trim()
                : "Name not provided",
            companyId: selectedCompany.id,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        setUsers(simpleUsers);
        const user = simpleUsers.find((u) => u.id === selectedUser?.id)
          ? selectedUser
          : null;

        setSelectedUser(user);
      } catch (error) {
        setUsersError("Failed to load experiences");
      } finally {
        isLoadingUsers.current = false;
      }
    };

    loadUsers();
  }, [
    selectedCompany,
    getCompanyUsers,
    setUsers,
    setSelectedUser,
    users,
    selectedUser,
    isLoadingUsers,
  ]);

  const isLoading = isLoadingExperiences.current || isLoadingUsers.current;
  const disableControls = isLoading || isLoadingRecordings;

  return (
    <>
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6 mt-4 ${
          companies.length > 1 ? "sm:justify-start" : "sm:justify-start"
        }`}
      >
        {companies.length > 1 && (
          <div className="flex flex-col w-full ">
            <FilterableSelect
              items={companies}
              selected={selectedCompany}
              setSelected={setSelectedCompany}
              renderLabel={(item) => item?.name ?? ""}
              headerText="Company"
              allowEmpty={false}
              disabled={disableControls}
            />
          </div>
        )}

        {!isLoading && experiences.length > 0 && (
          <>
            <div className="flex flex-col w-full">
              <SelectMenu
                items={experiences}
                selected={selectedExperience}
                setSelected={setSelectedExperience}
                renderLabel={(item) => item?.name ?? ""}
                headerText="Experience"
                disabled={disableControls}
              />
              {experiencesError && (
                <p className="text-red-600">{experiencesError}</p>
              )}
            </div>
            <div className="flex flex-col w-full">
              <FilterableSelect
                items={users}
                selected={selectedUser}
                setSelected={setSelectedUser}
                renderLabel={(item) => item?.name ?? ""}
                headerText="User"
                allowEmpty={true}
                emptyLabel="All users"
                disabled={disableControls}
              />
              {usersError && <p className="text-red-600">{usersError}</p>}
            </div>
          </>
        )}
      </div>
    </>
  );
}
