import { useLocation, useParams } from "react-router-dom";

import RecordingDetails from "../components/ExperienceVideos/RecordingDetails";
import useExperienceService from "../services/ExperienceService";
import { useEffect, useState } from "react";
import LoadingEllipsis from "../components/LoadingEllipsis";

export default function RecordingPage() {
  const location = useLocation();
  const { companyId, experienceId, recordingId } = useParams<{
    companyId: string;
    experienceId: string;
    recordingId: string;
  }>();

  const { getRecordingByExperience } = useExperienceService();

  const recordingFromState = location.state?.recording;
  const [recording, setRecording] = useState(recordingFromState || null);
  const [loading, setLoading] = useState(!recording);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Ensure that all required params are present
    if (!companyId || !experienceId || !recordingId) {
      setError("Missing parameters for company, experience, or recording.");
      setLoading(false);
      return;
    }

    if (!recording) {
      // If there's no recording in the state, fetch from the endpoint
      const fetchRecording = async () => {
        try {
          setLoading(true);
          const fetchedRecording = await getRecordingByExperience(
            companyId,
            experienceId,
            recordingId
          );
          setRecording(fetchedRecording); // Update the state with the fetched recording
        } catch (err) {
          setError("Failed to load recording.");
        } finally {
          setLoading(false);
        }
      };

      fetchRecording();
    }
  }, [
    companyId,
    experienceId,
    recordingId,
    getRecordingByExperience,
    recording,
  ]);

  if (loading) {
    return (
      <h1 className="text-white text-3xl ">
        Loading Recording
        <LoadingEllipsis />
      </h1>
    );
  }

  if (error) {
    return (
      <p className="text-red-600 mt-3">
        Error retrieving recording. Please try again.
      </p>
    );
  }

  if (!recording) {
    return <div>No recording data available for id: {recordingId}</div>;
  }

  return (
    <section>
      <RecordingDetails recording={recording} />
    </section>
  );
}
