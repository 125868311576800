import PageTitle from "../components/PageTitle";

export default function SdkPage() {
    return (
        <section className="flex flex-col gap-4">
        <PageTitle>SDK &amp; Tools</PageTitle>
        <p className="text-xl">To keep up with the latest SDK builds <a className="underline" target="_blank" href="https://discord.com/invite/THkkkGdZ2b" title="Join BeamXR on Discord">Join our discord</a></p>
        <h2 className="text-2xl underline">Core SDK</h2>
        <p className="text-xl">The core SDK gives you everything you need to stream directly from your applications.</p>
        <table className="table-auto w-full card border-spacing-2">
            <thead>
                <tr>
                <th className="border-t border-cyan-900 p-4">Package</th>
                    <th className="border-t border-cyan-900 p-4">Version</th>
                    <th className="border-t border-cyan-900 p-4 hidden md:table-cell">Release date</th>
                    <th className="border-t border-cyan-900 p-4 hidden md:table-cell">Description</th>
                    <th className="border-t border-cyan-900 p-4 hidden md:table-cell">Notes</th>
                    <th className="border-t border-cyan-900 p-4">Download</th>
                </tr>
            </thead>
            <tbody className="text-center">
                <tr>
                <td className="border-t border-cyan-900 p-4">BeamXR.Streaming</td>
                    <td className="border-t border-cyan-900 p-4">0.1.0</td>
                    <td className="border-t border-cyan-900 p-4 hidden md:table-cell">2024-10-10</td>
                    <td className="border-t border-cyan-900 p-4 hidden md:table-cell">Initial release</td>
                    <td className="border-t border-cyan-900 p-4 hidden md:table-cell text-left">
                        <p>Initial release with some known issues:</p>
                        <ul className="list-disc">
                            <li>Crackling on the microphone - will be resolved in the next release</li>
                            <li>No vulkan support on Android devices (Quest 2, Quest 3, Quest Pro, Pico), we're actively working on a fix for this, in the meantime please set your graphics API to <span className="font-bold">OpenGLES3</span></li>
                        </ul>
                    </td>
                    <td className="border-t border-cyan-900 p-4"><a className="underline" href="/assets/sdk/BeamXR.Streaming.0.1.0.unitypackage">Download unity package</a></td>
                </tr>
            </tbody>
        </table>
        <h2 className="text-2xl underline">Additional packages</h2>
        <p className="text-xl">Additional packages to help you interact with your experiences viewers and players in new ways.</p>
        <table className="table-auto w-full card border-spacing-2">
            <thead>
                <tr>
                    <th className="border-t border-cyan-900 p-4">Package</th>
                    <th className="border-t border-cyan-900 p-4">Version</th>
                    <th className="border-t border-cyan-900 p-4 hidden md:table-cell">Release date</th>
                    <th className="border-t border-cyan-900 p-4 hidden md:table-cell">Description</th>
                    <th className="border-t border-cyan-900 p-4 hidden md:table-cell">Notes</th>
                    <th className="border-t border-cyan-900 p-4">Download</th>
                </tr>
            </thead>
            <tbody className="text-center">
                <tr>
                    <td className="border-t border-cyan-900 p-4">BeamXR.Ovr</td>
                    <td className="border-t border-cyan-900 p-4">0.1.0</td>
                    <td className="border-t border-cyan-900 p-4 hidden md:table-cell">2024-10-10</td>
                    <td className="border-t border-cyan-900 p-4 hidden md:table-cell">Initial release</td>
                    <td className="border-t border-cyan-900 p-4 hidden md:table-cell text-left">
                        <p>The OVR package provides basic implementations for the authentication and streaming panels for studios using the OVR toolkit.</p>
                        <p>It is intended as quick start guidance.</p>
                    </td>
                    <td className="border-t border-cyan-900 p-4"><a className="underline" href="/assets/sdk/BeamXR.Ovr.0.1.0.unitypackage">Download unity package</a></td>
                </tr>
            </tbody>
        </table>
    </section>
    )
}