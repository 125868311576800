import { useEffect, useState } from "react";
import { PagedResults } from "../../types/PagedResults";
import { User, UserSummary } from "../../types/User";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUserService from "../../services/UserService";
import PageTitle from "../../components/PageTitle";
import ArrowUpwardTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
import ArrowDownwardTwoToneIcon from '@mui/icons-material/ArrowDownwardTwoTone';
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import Ghost from "../../components/Ghost";
import PositiveButton from "../../components/PositiveButton";

export default function AdminUserListPage() {
    const [isSearching, setIsSearching] = useState(false);
    const [results, setResults] = useState<PagedResults<UserSummary> | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    // Initialize formData with search params from URL if available, else use defaults
    const [formData, setFormData] = useState({
        searchValue: searchParams.get("searchValue") || "",
        page: parseInt(searchParams.get("page") || "1"),
        orderBy: searchParams.get("orderBy") || "name",
        orderDirection: searchParams.get("orderDirection") || "asc",
    });

    const { searchUsers } = useUserService();
    const debounceDelay = 500;

    const search = () => {
        setIsSearching(true);

        // Update the URL with current search parameters
        setSearchParams({
            searchValue: formData.searchValue,
            page: formData.page.toString(),
            orderBy: formData.orderBy,
            orderDirection: formData.orderDirection,
        });

        searchUsers(
            formData.searchValue,
            formData.page,
            formData.orderBy,
            formData.orderDirection
        )
            .then((searchResults) => {
                setResults(searchResults);
            })
            .catch((error) => {
                console.error("Error searching experiences:", error);
            })
            .finally(() => {
                setIsSearching(false);
            });
    };

    const handleInputChange = (e: any) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            searchValue: value,
            page: 1, // Reset to the first page when searching
        });
    };

    const handlePageChange = (newPage: number) => {
        setFormData((prev) => ({
            ...prev,
            page: newPage,
        }));
    };

    const handleSort = (column: string) => {
        setFormData((prev) => ({
            ...prev,
            orderBy: column,
            orderDirection: prev.orderBy === column && prev.orderDirection === "asc" ? "desc" : "asc", // Toggle direction
            page: 1, // Reset to first page on sort
        }));
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            search();
        }, debounceDelay);

        return () => {
            clearTimeout(handler);
        };
    }, [formData.searchValue, formData.page, formData.orderBy, formData.orderDirection]); // Watch for changes to formData

    useEffect(() => {
        search(); // Perform an initial search based on URL params when the component mounts
    }, []);

    return (
        <div className="flex flex-col gap-4">
            <section role="heading" className="grid grid-cols-1 xl:grid-cols-2">
                <PageTitle className="grow">Users</PageTitle>
                <div role="searchbox" className="flex flex-row gap-2 items-center">
                    <label htmlFor="search" className="hidden xl:w-auto xl:block leading-6">
                        Filter:
                    </label>
                    <div className="mt-2 w-full">
                        <input
                            type="text"
                            name="searchValue"
                            placeholder="Search term"
                            value={formData.searchValue}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded text-black"
                        />
                    </div>
                </div>
            </section>

            <section role="content">
                <table className="table-auto w-full card border-spacing-2">
                    <thead>
                        <tr className="">
                        <th className="font-normal text-left p-4 cursor-pointer" onClick={() => handleSort('email')}>
                                Email {formData.orderBy === 'email' && (formData.orderDirection === 'asc' ? <ArrowUpwardTwoToneIcon /> : <ArrowDownwardTwoToneIcon />)}
                            </th>
                            <th className="font-normal text-left p-4 cursor-pointer" onClick={() => handleSort('givenName')}>
                                Given name {formData.orderBy === 'givenName' && (formData.orderDirection === 'asc' ? <ArrowUpwardTwoToneIcon /> : <ArrowDownwardTwoToneIcon />)}
                            </th>
                            <th className="font-normal text-left p-4 cursor-pointer" onClick={() => handleSort('nickname')}>
                                Nickname {formData.orderBy === 'nickname' && (formData.orderDirection === 'asc' ? <ArrowUpwardTwoToneIcon /> : <ArrowDownwardTwoToneIcon />)}
                            </th>
                            <th className="font-normal text-left p-4 cursor-pointer" onClick={() => handleSort('familyName')}>
                                Family name {formData.orderBy === 'familyName' && (formData.orderDirection === 'asc' ? <ArrowUpwardTwoToneIcon /> : <ArrowDownwardTwoToneIcon />)}
                            </th>
                            <th className="text-left p-4"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {isSearching &&
                            Array.from({ length: 3 }, (_, i) => (
                                <tr className="border-t border-cyan-900" key={i}>
                                    {[...Array(4)].map((_, i) => (
                                    <td className="text-left p-4">
                                        <Ghost additionalClasses="p-4 rounded" />
                                    </td>
                                    ))}
                                </tr>
                            ))}

                        {!isSearching && results && (
                            <>
                                {!results.items || results.items.length === 0 ? (
                                    <tr className="border-t border-cyan-900">
                                        <td className="text-left p-4" colSpan={2}>
                                            No results found.
                                        </td>
                                    </tr>
                                ) : (
                                    results.items.map((user) => (
                                        <tr
                                            className="border-t border-cyan-900 hover:bg-cyan-300/25 cursor-pointer"
                                            key={user.id}
                                            onClick={() =>
                                                navigate(`/admin/users/${user.id}/`)
                                            }
                                            title={`View user ${user.email}`}
                                        >
                                            <td className="text-left p-4">{user.email}</td>
                                            <td className="text-left p-4">{user.givenName}</td>
                                            <td className="text-left p-4">{user.nickname}</td>
                                            <td className="text-left p-4">{user.familyName}</td>
                                            <td className="p-4 w-auto text-right">
                                                <ArrowForwardTwoToneIcon />
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </section>

            {/* Pagination Section */}
            {results && results.totalPages > 1 && (
                <section className="flex justify-between items-center mt-4">
                    <PositiveButton
                        onClick={() => handlePageChange(formData.page - 1)}
                        disabled={formData.page === 1}
                    >
                        Previous
                    </PositiveButton>
                    <span>
                        Page {formData.page} of {results.totalPages}
                    </span>
                    <PositiveButton
                        onClick={() => handlePageChange(formData.page + 1)}
                        disabled={formData.page === results.totalPages}
                    >
                        Next
                    </PositiveButton>
                </section>
            )}
        </div>
    );
}