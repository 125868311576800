import axios, { AxiosRequestConfig } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useState } from "react";
import { HttpError } from "../types/Error";

// Base URL from environment variable
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

const useApiClient = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [cachedToken, setCachedToken] = useState<string | null>(null);

  // Helper function to set up Axios config with Authorization header
  const getAxiosConfig = useCallback(async (): Promise<AxiosRequestConfig> => {
    if (!cachedToken) {
      const token = await getAccessTokenSilently();
      setCachedToken(token);
      return {
        baseURL: API_BASE_URL,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
    }
    return {
      baseURL: API_BASE_URL,
      headers: {
        Authorization: `Bearer ${cachedToken}`,
        "Content-Type": "application/json",
      },
    };
  }, [cachedToken, getAccessTokenSilently]);

  // Method to refresh the token and update the cache
  const refreshToken = useCallback(async () => {
    try {
      const freshToken = await getAccessTokenSilently({ cacheMode: "off" });
      setCachedToken(freshToken);
      console.log("Token refreshed successfully");
      return freshToken;
    } catch (error) {
      console.error("Failed to refresh token:", error);
    }
  }, [getAccessTokenSilently]);

  // Function to handle errors and return an HttpError object
  const handleHttpError = (error: any): HttpError => {
    if (axios.isAxiosError(error) && error.response) {
      return {
        errorCode: error.response.status,
        description: error.response.data?.message || "An error occurred",
      };
    }
    return {
      errorCode: 500,
      description: "Unknown error",
    };
  };

  // GET request
  const get = useCallback(
    async <T = any,>(
      endpoint: string,
      config?: AxiosRequestConfig
    ): Promise<T | HttpError> => {
      try {
        const axiosConfig = await getAxiosConfig();
        const response = await axios.get<T>(endpoint, {
          ...axiosConfig,
          ...config,
        });
        return response.data;
      } catch (error) {
        return handleHttpError(error);
      }
    },
    [getAxiosConfig]
  );

  // POST request
  const post = useCallback(
    async <T = any,>(
      endpoint: string,
      data: any,
      config?: AxiosRequestConfig
    ): Promise<T | HttpError> => {
      try {
        const axiosConfig = await getAxiosConfig();
        const response = await axios.post<T>(endpoint, data, {
          ...axiosConfig,
          ...config,
        });
        return response.data;
      } catch (error) {
        return handleHttpError(error);
      }
    },
    [getAxiosConfig]
  );

  // DELETE request
  const del = useCallback(
    async <T = any,>(
      endpoint: string,
      config?: AxiosRequestConfig
    ): Promise<T | HttpError> => {
      try {
        const axiosConfig = await getAxiosConfig();
        const response = await axios.delete<T>(endpoint, {
          ...axiosConfig,
          ...config,
        });
        return response.data;
      } catch (error) {
        return handleHttpError(error);
      }
    },
    [getAxiosConfig]
  );

  // PUT request
  const put = useCallback(
    async <T = any,>(
      endpoint: string,
      data: any,
      config?: AxiosRequestConfig
    ): Promise<T | HttpError> => {
      try {
        const axiosConfig = await getAxiosConfig();
        const response = await axios.put<T>(endpoint, data, {
          ...axiosConfig,
          ...config,
        });
        return response.data;
      } catch (error) {
        return handleHttpError(error);
      }
    },
    [getAxiosConfig]
  );

  return { get, post, delete: del, put, refreshToken };
};

export default useApiClient;
