import { useState } from "react";
import { useMe } from "../../context/MeContext";
import useCompanyService from "../../services/CompanyService";
import useExperienceService from "../../services/ExperienceService";
import useUserService from "../../services/UserService";
import { CompanyMembership } from "../../types/User";
import LoadingEllipsis from "../LoadingEllipsis";

interface UserMembershipCardProps {
    userEmail: string;
    userId: string;
    membership: CompanyMembership;
    onChangeMembership?: (companyId: string, membership: CompanyMembership) => void;
    allowChangeRole?: boolean;
}

export default function UserMembershipCard({userEmail, userId, membership, onChangeMembership, allowChangeRole}: UserMembershipCardProps) {

    const { me } = useMe();

    const {changeUserRoleAtCompany} = useCompanyService();

    const [role, setRole] = useState(membership.role);

    const [saving, setSaving] = useState(false);

    const roleTypes = [
        {id:"Developer", name:"Developer", description:"Can perform all developer actions on experiences"},
        {id:"Manager", name:"Manager", description:"Can perform all managerial actions on an organisation"},
        {id:"Owner", name:"Owner", description:"Can perform all actions on an organisation"},
    ]

    const canChangeRole = me!.roles.includes("administrator") && allowChangeRole !== false;

    const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const oldRole = role;

        setRole(e.target.value);

        setSaving(true);
        
        changeUserRoleAtCompany(membership.company.id, userId, userEmail, e.target.value)
            .then(() => {
                setSaving(false);
                if (onChangeMembership) {
                    onChangeMembership(membership.company.id, {...membership, role: e.target.value});
                }
            })
            .catch((error) => {
                console.error("Error updating user role:", error);
                setRole(oldRole);
                setSaving(false);
            });
    }

    return (
        <div
            className="card p-4 flex flex-row justify-between items-center gap-2">
            <div className="flex flex-col gap-2 grow">
            <p>{membership.company.name}{saving && <LoadingEllipsis />}</p>
            {canChangeRole ? (
                <select
                disabled={saving}
                name="companyId"
                value={role}
                onChange={handleRoleChange}
                className="w-full px-3 py-2 border rounded text-black"
              >
                {roleTypes.map((roleType) => <option key={roleType.id} value={roleType.id}>{roleType.name}</option>)}
              </select>
                ) : (
                    <p>{membership.role}</p>
                )}
            </div>
        </div>
    )
}