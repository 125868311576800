import moment from "moment";

/**
 * Formats a date as a relative time (e.g., "2 days ago").
 * Returns "Unknown" if the date is null or undefined.
 * @param date - The date to format.
 * @returns A string representing the relative time from now or "Unknown" if date is invalid.
 */
export function formatRelativeTime(
  date: string | Date | null | undefined
): string {
  if (!date) return "Unknown";
  return moment(date).fromNow();
}

/**
 * Formats a duration given in seconds into a human-readable format (e.g., "1 hour, 30 minutes").
 * @param seconds - The duration in seconds.
 * @returns A formatted string representing the duration.
 */
export function formatDuration(seconds: number): string {
  return moment.duration(seconds, "seconds").format("h [hours], m [minutes]");
}
