import { useMe } from "../context/MeContext";
import { Link, NavLink, useLocation } from "react-router-dom";
import VrpanoTwoToneIcon from "@mui/icons-material/VrpanoTwoTone";
import LiveHelpTwoToneIcon from "@mui/icons-material/LiveHelpTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import AssessmentTwoToneIcon from "@mui/icons-material/AssessmentTwoTone";
import HandymanTwoToneIcon from "@mui/icons-material/HandymanTwoTone";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import OndemandVideoTwoToneIcon from "@mui/icons-material/OndemandVideoTwoTone";

import { useAuth0 } from "@auth0/auth0-react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import NavigationItem, { NavigationItemDetails } from "./NavigationItem";

export default function Navigation() {
  const { me, isAdmin } = useMe();
  const { logout } = useAuth0();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const logoutUser = () => {
    // Log out the user
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  let navigation: NavigationItemDetails[] = isAdmin
    ? [
        {
          name: "My experiences",
          href: "/experiences",
          icon: VrpanoTwoToneIcon,
        },
        {
          name: "Administrator",
          href: "/admin",
          icon: AdminPanelSettingsTwoToneIcon,
          children: [
            {
              name: "Experiences",
              href: "/admin/experiences",
              icon: VrpanoTwoToneIcon,
            },
            {
              name: "Videos",
              href: "/admin/videos",
              icon: OndemandVideoTwoToneIcon,
            },
            {
              name: "Organisations",
              href: "/admin/companies",
              icon: BusinessTwoToneIcon,
            },
            { name: "Users", href: "/admin/users", icon: PeopleAltTwoToneIcon },
          ],
        },
        { name: "Analytics", href: "/analytics", icon: AssessmentTwoToneIcon },
      ]
    : [
        {
          name: "My experiences",
          href: "/experiences",
          icon: VrpanoTwoToneIcon,
        },
        {
          name: "User generated videos",
          href: "/videos",
          icon: OndemandVideoTwoToneIcon,
        },
        { name: "Analytics", href: "/analytics", icon: AssessmentTwoToneIcon },
      ];

  let secondaryNavigation: NavigationItemDetails[] = isAdmin
    ? [
        {
          name: "Profile",
          href: "/profile",
          icon: AccountCircleTwoToneIcon,
        },
      ]
    : [
        {
          name: "Tools & SDK",
          href: "/sdk",
          icon: HandymanTwoToneIcon,
        },
        {
          name: "Documentation",
          href: "/documentation",
          icon: MenuBookTwoToneIcon,
        },
        {
          name: "Support",
          href: "/support",
          icon: LiveHelpTwoToneIcon,
        },
        {
          name: "Profile",
          href: "/profile",
          icon: AccountCircleTwoToneIcon,
        },
      ];

  const navButtonStyle =
    "p-3 text-slate-100 hover:text-sky-500 text-base md:text-lg flex flex-row gap-2 hover-grow";

  return (
    <header>
      <nav
        aria-label="Global"
        className="mx-auto px-2 flex items-center justify-between sm:flex-col py-6 lg:py-8 text-white sm:h-screen"
      >
        <NavLink to="/" title="Developer home" className="-m-1.5 p-1.5 sm:mb-4">
          <span className="sr-only">BeamXR Developer Portal</span>
          <img src="/img/logo-dev.svg" alt="BeamXR Developer Portal" />
        </NavLink>

        <div className="flex sm:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex flex flex-row items-center justify-center rounded-md p-2.5"
          >
            <span className="sr-only">Open main menu</span>
            {mobileMenuOpen ? (
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            ) : (
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            )}
          </button>
        </div>
        <div className="hidden sm:flex sm:gap-y-2 sm:flex-col sm:justify-between w-full">
          {navigation.map((item) => (
            <NavigationItem
              key={item.name}
              icon={item.icon}
              text={item.name}
              to={item.href}
              children={item.children}
            />
          ))}
        </div>
        <div className="hidden sm:block sm:flex-1">{/* Spacer */}</div>
        <div className="hidden sm:flex sm:gap-y-2 sm:flex-col sm:justify-between w-full">
          {secondaryNavigation.map((item) => (
            <NavigationItem
              key={item.name}
              icon={item.icon}
              text={item.name}
              to={item.href}
              children={item.children}
            />
          ))}
          <a
            href="#logout"
            onClick={(e) => {
              e.preventDefault();
              logoutUser();
            }}
            className={`${navButtonStyle} text-slate-100`}
          >
            <span className="hidden xl:inline">
              <ExitToAppTwoToneIcon className="p-0" />
            </span>
            <span className="flex-1">Log out</span>
          </a>
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="sm:hidden"
      >
        <div className="fixed block h-screen inset-0 z-10" />
        <DialogPanel className="fixed top-16 inset-y-0 right-0 z-10 w-full overflow-y-auto bg-slate-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 text-white flex flex-col">
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base leading-7 hover:bg-indigo-950"
                  >
                    <item.icon className="h-6 w-6 mx-2" />
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="grow" />
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {secondaryNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-sm leading-7 hover:bg-indigo-950"
                  >
                    <item.icon className="h-6 w-6 mx-2" />
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-6">
                <button
                  title="logout"
                  onClick={(e) => {
                    e.preventDefault();
                    logoutUser();
                  }}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base leading-7 hover:bg-indigo-950"
                >
                  <ExitToAppTwoToneIcon className="h-6 w-6 mx-2" />
                  Log out
                </button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
