import { useEffect, useState } from "react";
import Ghost from "../Ghost";
import { Company } from "../../types/Me";
import useExperienceService from "../../services/ExperienceService";
import { Experience } from "../../types/Experience";
import { HttpError } from "../../types/Error";
import ExperienceCard from "./ExperienceCard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link } from "react-router-dom";
import ExperienceListItem from "./ExperienceListItem";

interface CompanyExperiencesProps {
  company: Company;
}

export default function CompanyExperiences({
  company,
}: CompanyExperiencesProps) {
  const [loading, setLoading] = useState(false);
  const [experiences, setExperiences] = useState<Experience[] | null>(null);
  const { getExperiencesForCompany } = useExperienceService();
  const [title, setTitle] = useState<string>(company.name);

  useEffect(() => {
    if (loading || experiences) {
      return;
    }

    setLoading(true);

    getExperiencesForCompany(company.id)
      .then((loadedExperiences) => {
        setExperiences(loadedExperiences);

        if (loadedExperiences.length === 0) {
          setTitle(`${company.name} has no experiences`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loading]);

  return (
    <section className="flex flex-col gap-2">
      <h2 className="text-2xl">{title}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2">
        {loading ? (
          <>
            <Ghost additionalClasses="rounded h-36" />
            <Ghost additionalClasses="rounded h-36" />
            <Ghost additionalClasses="rounded h-36" />
          </>
        ) : (
          <>
            {experiences && (
              <>
                {experiences.map((experience) => (
                  <ExperienceListItem key={experience.id} experience={experience} />
                ))}
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
}
