import React, { useEffect, useState } from "react";
import useCompanyService from "../services/CompanyService";
import { HttpError } from "../types/Error";
import useApiClient from "../services/ApiClient";
import { Company, UpdateCompanyDetails } from "../types/Company";
import useUrlUtilities from "../utilities/UrlUtilities";
import PositiveButton from "./PositiveButton";

interface CreateFirstOrganisationProps {
  onSuccessfulCreation: () => void;
}

export default function CreateFirstOrganisation(
  props: CreateFirstOrganisationProps
) {
  const { registerCompany, updateCompany } = useCompanyService();
  const {refreshToken} = useApiClient();

  const [formData, setFormData] = useState({
    companyName: "",
    websiteUrl: "",
    description: "",
    organisationType: "",
  });

  const {isValidUrl} = useUrlUtilities();

  const [submitted, setSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    if (submitted && formData) {
      validateForm();
    }
  }, [formData, submitted]);

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};

    if (formData.companyName.trim() === "") {
      newErrors.companyName = "Company name is required";
      isValid = false;
    }

    if (formData.websiteUrl.trim() !== "") {
      // Check the website url is a valid url using regex.
      if (!isValidUrl(formData.websiteUrl.trim())) {
        newErrors.websiteUrl =
          "Url is not a valid url in the form https://company.com";
        isValid = false;
      }
    }

    if (formData.organisationType === "") {
      newErrors.organisationType =
        "Please select a value for your organisation type";
      isValid = false;
    }

    setValidationErrors(newErrors);

    return isValid;
  };

  const saveOrganisation = async () => {
    setSubmitted(true);

    if (!validateForm()) return;

    setIsSaving(true);

    try {
      // Register the company
      const newCompany = await registerCompany(formData.companyName);

      // Wait 2 seconds to allow the new roles to propagate
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Refresh the token
      await refreshToken();

      const updateCompanyDetails: UpdateCompanyDetails = {
        name: formData.companyName,
        companyId: newCompany.id,
        description: formData.description,
        type: formData.organisationType,
        websiteUrl: formData.websiteUrl,
      };

      // Update the company with the new data
      await updateCompany(updateCompanyDetails);

      // Call the success handler
      props.onSuccessfulCreation();
    } catch (err) {
      const newErrors: any = {
        companyName:
          "Failed to create company. Please try again. If the problem persists, please contact support.",
      };
      setValidationErrors(newErrors);
    } finally {
      setIsSaving(false);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validStyle = (fieldName: string) => {
    // Find the validationErrors key with the fieldName.
    let valid = Object.keys(validationErrors).indexOf(fieldName) === -1;

    return valid ? "" : "border-red-800";
  };

  const validationError = (fieldName: string): null | string => {
    return validationErrors[fieldName] || null;
  };

  const isFormValid = submitted && Object.keys(validationErrors).length === 0;

  return (
    <div className="bg-gray-30 mt-0 md:pt-40">
      <div className="flex flex-col items-center space-y-4">
        <img src="/img/logo-dev.svg" alt="BeamXR Developer Portal" />
        <div className="text-white px-4 sm:px-0 text-center">
          <p className="text-lg ">
            Before we get started, please tell us a little bit about your
            organisation.
          </p>
          <p className="text-sm">
            (You can complete your company profile later).
          </p>
        </div>

        <div className="rounded-xl bg-cyan-950 p-4 text-white mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4 mx-4">
          {/* Company Name */}
          <div className="sm:col-span-4">
            <label htmlFor="companyName" className="block leading-6">
              Company name:
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="companyName"
                placeholder="XYZ Corp *"
                autoComplete="organization"
                value={formData.companyName}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                  "companyName"
                )}`}
              />
            </div>
            {validationError("companyName") && (
              <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                {validationError("companyName")}
              </div>
            )}
          </div>

          {/* Website URL */}
          <div className="sm:col-span-4">
            <label htmlFor="websiteUrl" className="block leading-6">
              Website:
            </label>
            <div className="mt-2">
              <input
                type="url"
                name="websiteUrl"
                placeholder="https://supergamestud.io"
                value={formData.websiteUrl}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded text-black"
              />
            </div>
            {validationError("websiteUrl") && (
              <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                {validationError("websiteUrl")}
              </div>
            )}
          </div>

          {/* Description */}
          <div className="sm:col-span-4">
            <label htmlFor="description" className="block leading-6">
              Description:
            </label>
            <div className="mt-2">
              <textarea
                placeholder="Description"
                name="description"
                value={formData.description}
                rows={4}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded text-black"
              />
            </div>
            {validationError("description") && (
              <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                {validationError("description")}
              </div>
            )}
          </div>

          {/* Organisation Type Dropdown */}
          <div className="sm:col-span-4">
            <label htmlFor="organisationType" className="block leading-6">
              Which of these best describes your type of organisation?:
            </label>
            <select
              name="organisationType"
              value={formData.organisationType}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded text-black"
            >
              <option value="">Please select</option>
              <option value="Developer">Developer</option>
              <option value="Publisher">Publisher</option>
              <option value="Both">Both</option>
              <option value="Other">Other</option>
            </select>
            {validationError("organisationType") && (
              <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                {validationError("organisationType")}
              </div>
            )}
          </div>
        </div>
        {/* Submit Button */}
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <PositiveButton
            onClick={saveOrganisation}
            disabled={isSaving}>
            {isSaving ? "Saving..." : "Save"}
          </PositiveButton>
        </div>
      </div>
    </div>
  );
}
