import { useCallback } from "react";
import useApiClient from "./ApiClient";
import { HttpError } from "../types/Error";

const useRecordingService = () => {
  const { get } = useApiClient();

  const getRecordingScreenshot = useCallback(
    async (id: string): Promise<Blob> => {
      try {
        const response = await get(`/recordings/${id}/screenshot`, {
          responseType: "blob",
        });

        if (response instanceof Blob) {
          return response;
        }

        const errorResponse: HttpError = await response.json();
        throw new Error(errorResponse.description);
      } catch (error) {
        console.error("Error getting recording thumbnail:", error);
        throw error;
      }
    },
    [get]
  );

  const getWatchUrl = useCallback(
    async (id: string): Promise<string> => {
      try {
        const response = await get(`/recordings/${id}/watch`);

        // Handle response errors
        if (response.errorCode) {
          console.error("Error in watch URL response:", response);
          throw new Error(response.errorCode);
        }

        // Assuming the response is just a string (the URL)
        return response as string;
      } catch (error) {
        console.error("Error fetching recording watch URL:", error);
        throw error;
      }
    },
    [get]
  );

  const getDownloadUrl = useCallback(
    async (id: string): Promise<string> => {
      try {
        const response = await get(`/recordings/${id}/download`);

        // Handle response errors
        if (response.errorCode) {
          console.error("Error in watch URL response:", response);
          throw new Error(response.errorCode);
        }

        // Assuming the response is just a string (the URL)
        return response as string;
      } catch (error) {
        console.error("Error fetching recording download URL:", error);
        throw error;
      }
    },
    [get]
  );

  return {
    getRecordingScreenshot,
    getWatchUrl,
    getDownloadUrl,
  };
};

export default useRecordingService;
