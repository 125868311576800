import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import { useNavigate, useLocation, useParams, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { Experience } from "../../types/Experience";
import { ExperienceKey } from "../../types/ExperienceKey";
import useExperienceService from "../../services/ExperienceService";
import useCompanyService from "../../services/CompanyService";
import { Company } from "../../types/Company";
import PageTitle from "../../components/PageTitle";
import LoadingEllipsis from "../../components/LoadingEllipsis";
import ExperienceDetailForm from "../../components/Experiences/ExperienceDetailForm";
import Ghost from "../../components/Ghost";
import ExperienceKeyCard from "../../components/Experiences/ExperienceKeyCard";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import ExperienceBlockCard from "../../components/Experiences/ExperienceBlockCard";
import BackButton from "../../components/BackButton";
import PositiveButton from "../../components/PositiveButton";

export default function AdminExperienceDetailPage() {
  const navigate = useNavigate();
  const { companyId, experienceId } = useParams();
  const { getExperience, getExperienceKeys, createNewExperienceKey } =
    useExperienceService();
  const { getCompany } = useCompanyService();

  const [isAddingKey, setIsAddingKey] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const [company, setCompany] = useState<Company | null>(null);
  const [experience, setExperience] = useState<Experience | null>(null);
  const [experienceKeys, setExperienceKeys] = useState<ExperienceKey[] | null>(
    null
  );

  useEffect(() => {
    if (!initiated) {
      setIsLoading(true);
      setInitiated(true);
    }

    getExperience(companyId as string, experienceId as string)
      .then((experience) => {
        setExperience(experience);
      })
      .catch((error) => {
        console.error("Error fetching experience:", error);
      });

    getExperienceKeys(companyId as string, experienceId as string)
      .then((keys) => {
        setExperienceKeys(keys);
      })
      .catch((error) => {
        console.error("Error fetching experience keys:", error);
      });

    getCompany(companyId as string)
      .then((company) => {
        setCompany(company);
      })
      .catch((error) => {
        console.error("Error fetching company:", error);
      });
  }, [initiated]);

  useEffect(() => {
    if (experience && experienceKeys && company) {
      setIsLoading(false);
    }
  }, [experience, experienceKeys, company]);

  const handleDeleteKey = (key: ExperienceKey) => {
    const newKeys = experienceKeys?.filter((k) => k.key !== key.key) ?? [];

    setExperienceKeys(newKeys);
  };

  const addNewKey = () => {
    setIsAddingKey(true);

    createNewExperienceKey(companyId as string, experienceId as string)
      .then((key) => {
        setExperienceKeys([...(experienceKeys ?? []), key]);
      })
      .catch((error) => {
        console.error("Error adding new key:", error);
        alert("Failed to add new key. Please try again.");
      })
      .finally(() => {
        setIsAddingKey(false);
      });
  };

  return (
    <section className="flex flex-col gap-2 divide-stone-900">
      <div className="flex flex-row">
        <BackButton />
        <PageTitle className="text-4xl">
          {experience ? experience.name : `Loading`}{" "}
          {!experience && <LoadingEllipsis />}
        </PageTitle>
      </div>
      <div className="grid grid-cols-1 2xl:grid-cols-3 gap-4">
        <div className="flex flex-col gap-2 col-span-2">
          <h2 className="text-2xl">Company</h2>
          {company ? (
            <NavLink
              to={`/admin/companies/${company.id}`}
              className="flex flex-col gap-2 p-4 card"
            >
              <div className="flex flex-row gap-2 items-center">
                <div className="flex flex-col gap-2 grow">
                  <p>{company.name}</p>
                  <p>{company.description}</p>
                </div>
                <ArrowForwardTwoToneIcon />
              </div>
            </NavLink>
          ) : (
            <Ghost additionalClasses="h-24 rounded" />
          )}
          <h2 className="text-2xl">Details</h2>
          {experience ? (
            <ExperienceDetailForm experience={experience} />
          ) : (
            <Ghost additionalClasses="h-24 col-span-2 rounded" />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="text-2xl">Experience control</h2>
          {experience ? (
            <ExperienceBlockCard experience={experience} />
          ) : (
            <Ghost additionalClasses="h-24 rounded" />
          )}
          <h2 className="text-2xl">Keys</h2>
          {experienceKeys ? (
            experienceKeys.map((key) => (
              <ExperienceKeyCard
                key={key.key}
                experienceKey={key}
                onDelete={handleDeleteKey}
                deleteDisabled={experienceKeys.length == 1}
                experienceId={experience!.id!}
                companyId={experience!.companyId}
              />
            ))
          ) : (
            <Ghost additionalClasses="h-24 rounded" />
          )}
          {!isLoading && (
            <PositiveButton
              onClick={addNewKey}
              disabled={isLoading || isAddingKey}
            >
              <AddTwoToneIcon className="h-8 w-8" />
              <span>
                {isAddingKey ? "Adding key" : "Add key"}
                {isAddingKey && <LoadingEllipsis />}
              </span>
            </PositiveButton>
          )}
        </div>
      </div>
    </section>
  );
}
