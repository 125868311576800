import { Link, NavLink } from "react-router-dom";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import VrpanoTwoToneIcon from "@mui/icons-material/VrpanoTwoTone";
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import PageTitle from "../../components/PageTitle";

interface LinkCardProps {
  to: string;
  title: string;
  description: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
}

function LinkCard({ to, title, description, icon:Icon }: LinkCardProps) {
  return (
    <NavLink to={to} className="card p-4 flex flex-row gap-4">
      <div className="flex items-center h-full">
        <Icon />
      </div>
      <div className="flex flex-col gap-2 grow">
        <h2 className="text-lg xl:text-2xl">{title}</h2>
        <p>{description}</p>
      </div>
      <div className="flex items-center h-full">
        <ArrowForwardTwoToneIcon />
      </div>
    </NavLink>
  );
}

export default function AdminHomePage() {
  return (
    <div className="flex flex-col gap-4">
      <PageTitle>Administrator</PageTitle>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
        <LinkCard to="/admin/experiences" title="Experiences" description="Manage experiences" icon={VrpanoTwoToneIcon} />
        <LinkCard to="/admin/companies" title="Organisations" description="Manage organisations" icon={BusinessTwoToneIcon} />
        <LinkCard to="/admin/users" title="Users" description="Manage users" icon={PeopleAltTwoToneIcon} />
      </div>
    </div>
  );
}
