import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useMe } from "../../context/MeContext";
import useCompanyService from "../../services/CompanyService";
import useExperienceService from "../../services/ExperienceService";
import { useEffect, useState } from "react";
import { Company } from "../../types/Company";
import { User, UserAndRole } from "../../types/User";
import InviteToUserCompanyForm from "../../components/User/InviteUserToCompanyForm";
import BackButton from "../../components/BackButton";
import PageTitle from "../../components/PageTitle";
import LoadingEllipsis from "../../components/LoadingEllipsis";
import CompanyDetailForm from "../../components/Company/CompanyDetailForm";
import Ghost from "../../components/Ghost";
import PositiveButton from "../../components/PositiveButton";
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import UserMembershipCard from "../../components/User/UserMembershipCard";
import CompanyMembershipCard from "../../components/User/CompanyMembershipCard";

export default function ProfileCompanyPage() {
    const navigate = useNavigate();
    const { companyId } = useParams();
    const { getCompany, updateCompany, getCompanyUsers } = useCompanyService();
    const { getExperiencesForCompany } = useExperienceService();
    const [inviteUserFormOpen, setInviteUserFormOpen] = useState(false);
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [initiated, setInitiated] = useState(false);
    const [company, setCompany] = useState<Company | null>(null);
    const [users, setUsers] = useState<UserAndRole[] | null>(null);

    // Check to see if you're an owner of the company.
    const { me } = useMe();

    const isOwner = me?.memberships?.some(m => m.company.id === companyId && m.role.toLowerCase() === "owner");

    const isAdmin = me?.roles.includes("administrator");
  
    useEffect(() => {
      if (!initiated) {
        setIsLoading(true);
        setInitiated(true);
      }
  
      getCompany(companyId as string)
        .then((company) => {
          setCompany(company);
        })
        .catch((error) => {
          console.error("Error fetching company:", error);
        });
  
      getCompanyUsers(companyId as string)
        .then((users) => {
          setUsers(users);
        })
        .catch((error) => {
          console.error("Error fetching company users:", error);
        });
  
    }, [initiated]);
  
    useEffect(() => {
      if (company && users) {
        setIsLoading(false);
      }
    }, [company, users]);
  
    const handleUserInvited = (email: string) => {
      setInviteUserFormOpen(false);
      setIsLoading(true);
      setUsers(null);
      getCompanyUsers(companyId as string)
        .then((users) => {
          setUsers(users);
        })
        .catch((error) => {
          console.error("Error fetching company users:", error);
        });
    }
  
    return (
      <>
        <InviteToUserCompanyForm
          open={inviteUserFormOpen}
          setOpen={setInviteUserFormOpen}
          companyId={companyId}
          onComplete={handleUserInvited} />
        <section className="flex flex-col gap-2 divide-stone-900">
          <div className="flex flex-row">
            <BackButton />
            <PageTitle className="text-4xl">
              {company ? company.name : `Loading`}{" "}
              {!company && <LoadingEllipsis />}
            </PageTitle>
          </div>
          <div className="grid grid-cols-1 2xl:grid-cols-3 gap-4">
            <div className="flex flex-col gap-2 col-span-2">
              <h2 className="text-2xl">Details</h2>
              {company ? (
                <CompanyDetailForm company={company} />
              ) : (
                <Ghost additionalClasses="h-24 col-span-2 rounded" />
              )}
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="text-2xl">Users</h2>
              {users ? (
                users.map((user) => (
                  <CompanyMembershipCard
                    key={user.id}
                    userEmail={user.email}
                    userId={user.id}
                    membership={{company: company!, role: user.role}}
                    allowChangeRole={isOwner || isAdmin} />
                ))
              ) : (
                <Ghost additionalClasses="h-24 rounded" />
              )}
              {users && users.length === 0 && <p>This company has no users</p>}
              {!isLoading && (isOwner || isAdmin) && (
                <PositiveButton
                onClick={() => { setInviteUserFormOpen(true); }}
                  disabled={isLoading || isAddingUser}
                >
                  <AddTwoToneIcon className="h-8 w-8" />
                  <span>
                    {isAddingUser ? "Inviting user" : "Invite user"}
                    {isAddingUser && <LoadingEllipsis />}
                  </span>
                </PositiveButton>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
  