import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { CompanySummary } from "../../types/Company";
import { ExperienceSummary } from "../../types/Experience";
import { SimpleUser } from "../../types/User";

export function useUpdateQueryParams(
  selectedCompany: CompanySummary | null,
  selectedExperience: ExperienceSummary | null,
  selectedUser: SimpleUser | null,
  page: number
) {
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams();

    if (selectedCompany?.id) searchParams.set("company", selectedCompany.id);
    if (selectedExperience?.id)
      searchParams.set("experience", selectedExperience.id);
    if (selectedUser?.id) searchParams.set("user", selectedUser.id);
    if (page > 1) searchParams.set("page", String(page));

    navigate(`?${searchParams.toString()}`, { replace: true });
  }, [selectedCompany, selectedExperience, selectedUser, page, navigate]);
}
