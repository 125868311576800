import { useState, useCallback, useEffect, useRef } from "react";

import { PagedResults } from "../../types/PagedResults";
import { Recording } from "../../types/Recording";
import useExperienceService from "../../services/ExperienceService";
import { SimpleUser } from "../../types/User";
import { CompanySummary } from "../../types/Company";
import { ExperienceSummary } from "../../types/Experience";

interface UseRecordingsProps {
  selectedCompany: CompanySummary | null;
  selectedExperience: ExperienceSummary | null;
  selectedUser: SimpleUser | null;
  page: number;
  setPage: (page: number) => void; // Ensure setPage is passed from parent
}

export default function useRecordings({
  selectedCompany,
  selectedExperience,
  selectedUser,
  page,
  setPage,
}: UseRecordingsProps) {
  const { getRecordingsByExperience } = useExperienceService();
  const [recordings, setRecordings] = useState<PagedResults<Recording> | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // Track the previous experience and user selections
  const prevExperienceIdRef = useRef<string | null>(
    selectedExperience?.id || null
  );
  const prevUserIdRef = useRef<string | null>(selectedUser?.id || null);

  // fetch recordings will fire when the dependencies change
  const fetchRecordings = useCallback(
    async (ITEMS_PER_PAGE: number, users: SimpleUser[]) => {
      if (!selectedCompany || !selectedExperience) {
        return;
      }

      setIsLoading(true);
      setIsError(false);

      try {
        const params = {
          itemsPerPage: ITEMS_PER_PAGE,
          page: page,
          userId: selectedUser ? selectedUser.id : undefined,
        };

        const recordingsData = await getRecordingsByExperience(
          selectedCompany.id,
          selectedExperience.id,
          params
        );

        const recordingsWithUser = recordingsData.items.map((recording) => {
          const user = users.find((u) => u.id === recording.userId);
          return { ...recording, user };
        });

        setRecordings({ ...recordingsData, items: recordingsWithUser });
      } catch (error) {
        console.error("Error fetching recordings:", error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [
      getRecordingsByExperience,
      page,
      selectedCompany,
      selectedExperience,
      selectedUser,
    ]
  );

  // Effect to check for experience or user change, and reset the page to 1 if they change
  useEffect(() => {
    const currentExperienceId = selectedExperience?.id || null;
    const currentUserId = selectedUser?.id || null;

    // Check if selectedExperience or selectedUser has changed
    if (
      currentExperienceId !== prevExperienceIdRef.current ||
      currentUserId !== prevUserIdRef.current
    ) {
      setPage(1); // Reset page to 1 when experience or user changes
    }

    // Update the refs with the current values
    prevExperienceIdRef.current = currentExperienceId;
    prevUserIdRef.current = currentUserId;
  }, [selectedExperience, selectedUser, setPage]);

  return {
    recordings,
    setRecordings,
    isLoading,
    isError,
    fetchRecordings,
  };
}
