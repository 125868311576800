import { useState } from "react";
import ContentPasteTwoToneIcon from '@mui/icons-material/ContentPasteTwoTone';
import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';

interface CopyBoxProps {
    text: string;
    additionalClasses?: string;
    textBoxClasses?: string;
}

// Component which allows the contents of the box to be copied to the clipboard.
export default function CopyBox({text, additionalClasses, textBoxClasses}:CopyBoxProps) {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    return (
        <div className={`flex flex-row gap-2 ${additionalClasses}`}>
            <p className={`grow ${textBoxClasses}`}>{text}</p>
            <button onClick={copyToClipboard} className="btn btn-primary" title={copied ? "Copied to clipboard" : "Copy to clipboard"}>
                {copied ? <AssignmentTurnedInTwoToneIcon className="h-8 w-8" /> : <ContentPasteTwoToneIcon className="h-8 w-8" />}
            </button>
        </div>
    )
}