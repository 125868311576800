import { HttpError } from "../types/Error";
import { PagedResults } from "../types/PagedResults";
import { UpdatedUser, User, UserSummary } from "../types/User";
import useApiClient from "./ApiClient";

const useUserService = () => {
    const { get, post, put, delete: del } = useApiClient();

    const getUser = async (userId: string): Promise<User> => {
        try {
            const result = await get<User>(`/users/${userId}`);
            // Check if the response is an error.
            if ((result as HttpError).errorCode) {
                throw (result as HttpError).description;
            }
            
            return result as User;
        } catch (error) {
            console.error('Error getting user:', error);
            throw error;
        }
    }

    const searchUsers = async (searchTerm?:string, page?:number, orderBy?:string, orderDirection?:string): Promise<PagedResults<UserSummary>> => {
        try {

            const postData = {
                searchTerm,
                page: page ?? 1,
                itemsPerPage: 10,
                orderBy: orderBy ?? "name",
                orderDirection: orderDirection ?? "asc"
            };

            const result = await post<PagedResults<UserSummary>>(`/users/search`, postData);
            
            // Check if the response is an error.
            if ((result as HttpError).errorCode) {
                throw (result as HttpError).description;
            }
            
            return result as PagedResults<UserSummary>;

        } catch (error) {
            console.error('Error searching users:', error);
            throw error;
        }
    }

    const updateUser = async (user: UpdatedUser): Promise<User> => {
        try {
            const result = await put<User>(`/users/${user.id}`, user);
            // Check if the response is an error.
            if ((result as HttpError).errorCode) {
                throw (result as HttpError).description;
            }
            
            return result as User;
        } catch (error) {
            console.error('Error updating user:', error);
            throw error;
        }
    }

    return {getUser, searchUsers, updateUser};
}

export default useUserService;