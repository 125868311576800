import React from "react";

interface GhostProps {
  additionalClasses?: string;
}

const Ghost: React.FC<GhostProps> = ({ additionalClasses = "h-12 w-full" }) => {
  return (
    <div
      className={`relative overflow-hidden bg-slate-300 animate-pulse ${additionalClasses}`}
    >
      <div className="absolute inset-0 bg-gradient-to-r from-slate-300 via-slate-700 to-slate-300 opacity-50 animate-shimmer"></div>
    </div>
  );
};

export default Ghost;
