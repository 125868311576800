import { useState, useEffect, useCallback } from "react";
import "moment-duration-format";

import {
  formatDuration,
  formatRelativeTime,
} from "../../utilities/DateUtilities";
import DownloadIcon from "@mui/icons-material/Download";
import { Link } from "react-router-dom";
import { SimpleUser } from "../../types/User";
import { Recording } from "../../types/Recording";
import useRecordingService from "../../services/RecordingSerice";

interface RecordingCardProps {
  recording: Recording & { user?: SimpleUser };
  companyId: string | undefined;
}

function RecordingCard({ recording, companyId }: RecordingCardProps) {
  const { getRecordingScreenshot, getDownloadUrl } = useRecordingService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [thumbnail, setThumbnail] = useState<string | null>(null);

  const formattedStarted = formatRelativeTime(recording.started);
  const formattedDuration = formatDuration(recording.duration);

  const loadThumbnail = useCallback(
    async function (id: string) {
      if (thumbnail || isLoading) return;

      setIsLoading(true);

      try {
        const thumbData = await getRecordingScreenshot(id);

        // Create a URL for the blob (assuming thumbData is binary data)
        const blobUrl = URL.createObjectURL(thumbData);
        setThumbnail(blobUrl);
      } catch (error) {
        console.error("Failed to load thumbnail:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [getRecordingScreenshot, thumbnail, isLoading]
  );

  useEffect(
    function () {
      if (recording?.hasScreenshot && !thumbnail) {
        loadThumbnail(recording.id);
      }
    },
    [recording, loadThumbnail, thumbnail]
  );

  const handleDownload = useCallback(async () => {
    setIsLoading(true);

    try {
      const downloadUrl: string = await getDownloadUrl(recording.id);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${recording.id}.mp4`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Failed to fetch download URL:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getDownloadUrl, recording.id]);

  const handleDownloadClick = () => {
    if (!isLoading) {
      handleDownload();
    }
  };

  const userName = recording.user?.name || "Unknown User";

  if (!recording) {
    return null;
  }

  return (
    <>
      <Link
        to={`/companies/${companyId}/experiences/${recording.experienceId}/recordings/${recording.id}`}
        state={{ recording }}
        className="rounded-2xl shadow-lg drop-shadow-md bg-cyan-950 border border-translucentWhite backdrop-blur-[5px] cursor-pointer relative"
      >
        {recording.hasScreenshot ? (
          <div
            className="bg-50% bg-center bg-no-repeat pt-[56.25%] rounded-t-2xl"
            style={{
              backgroundImage: thumbnail ? `url(${thumbnail})` : undefined,
              backgroundSize: "cover",
            }}
          />
        ) : (
          <div className="bg-logo bg-50% bg-translucentWhite bg-center bg-no-repeat pt-[56.25%] rounded-t-2xl" />
        )}
        <div className="p-4">
          <div className="mt-2">
            <span className="font-bold">Recorded by:</span> {userName}
          </div>
          {formattedStarted} - {formattedDuration} long
        </div>

        <button
          className="absolute bottom-4 right-2 p-2 bg-white text-cyan-950 rounded-full shadow-lg hover:bg-cyan-600 hover:text-white transition "
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDownloadClick();
          }}
          aria-label="Download recording"
        >
          <DownloadIcon className="h-6 w-6" />
        </button>
      </Link>
    </>
  );
}

export default RecordingCard;
