import { useState } from "react";
import PageTitle from "../components/PageTitle";
import PositiveButton from "../components/PositiveButton";
import { useMe } from "../context/MeContext";
import { PopupModal } from "react-calendly";
import { NavLink } from "react-router-dom";

function Greeting() {

    const { me } = useMe();

    // Work out a greeting based on time of day.
    let greeting = "Hello";

    const now = new Date();

    if (now.getHours() < 12) {
        greeting = "Good morning";
    } else if (now.getHours() < 18) {
        greeting = "Good afternoon";
    } else {
        greeting = "Good evening";
    }

    return `${greeting} ${me?.givenName}`
}

export default function SupportPage() {

    const { me } = useMe();

    const [calendlyOpen, setCalendlyOpen] = useState(false);

    const calendlyUrl = "https://calendly.com/d/cpmn-yzg-g7d/beamxr-technical-team";

    return (
        <>
        <PopupModal 
            url={calendlyUrl} 
            rootElement={document.getElementById("root") as HTMLElement} 
            onModalClose={() => setCalendlyOpen(false)}
            open={calendlyOpen} />
        <section className="flex flex-col gap-4">
            <PageTitle>Support</PageTitle>
            <p className="text-2xl">{Greeting()}. There are a number of ways to get support with BeamXR</p>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div className="card p-4 flex flex-col gap-4 justify-between">
                    <p className="text-xl">Our discord community is a great place to get help and support from our team and other developers using BeamXR. You can join our discord server by clicking the link below:</p>
                    <a href="https://discord.com/invite/THkkkGdZ2b" target="_blank" className="px-4 py-2 w-auto rounded border text-cyan-300 border-cyan-300 hover:bg-cyan-300/25 text-center">Join BeamXR on Discord</a>
                </div>
                <div className="card p-4 flex flex-col gap-4 justify-between">
                    <p className="text-xl">You can get in touch with the technical team by booking a meeting using the following link:</p>
                    <PositiveButton onClick={() => {setCalendlyOpen(true)}}>Book a meeting with the team</PositiveButton>
                </div>
                <div className="card p-4 flex flex-col gap-4 justify-between">
                    <p className="text-xl">We're constantly adding new documentation and tutorials to the documentation page. Head over there to take a look.</p>
                    <NavLink to="/documentation" className="px-4 py-2 w-auto rounded border text-cyan-300 border-cyan-300 hover:bg-cyan-300/25 text-center">Visit the documentation</NavLink>
                </div>
            </div>
        </section >
        </>
    );
}