import { useNavigate } from "react-router-dom";
import { useMe } from "../../context/MeContext";
import { ReactNode, useState } from "react";
import { Experience } from "../../types/Experience";
import NewExperienceForm from "./NewExperienceForm";
import CompanyExperiences from "./CompanyExperiences";
import AddIcon from '@mui/icons-material/Add';
import PositiveButton from "../PositiveButton";

interface ExperienceListProps {
    header?: ReactNode
}

export default function ExperienceList({header = <h1 className="text-4xl">Your experiences</h1>}: ExperienceListProps) {
    const { me } = useMe();
    const navigate = useNavigate();
    const [newCompanyOpen, setNewCompanyOpen] = useState(false);
    
    const newExperienceAdded = (experience: Experience) => {
      setNewCompanyOpen(false);
      navigate(`/experiences/${experience.companyId}/${experience.id}`);
    };
  
    return (
      <>
        <NewExperienceForm
          open={newCompanyOpen}
          setOpen={setNewCompanyOpen}
          onSaved={newExperienceAdded}
        />
        <section role="list" className="flex flex-col gap-2">
          <div className="flex flex-col sm:flex-row justify-between gap-2">
            {header}
            <PositiveButton onClick={() => setNewCompanyOpen(true)}><AddIcon /> Add experience</PositiveButton>
          </div>
          {me?.memberships.map((membership) => (
            <CompanyExperiences
              key={membership.company.id}
              company={membership.company}
            />
          ))}
        </section>
      </>
    );
  }