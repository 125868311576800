import React, { ButtonHTMLAttributes, FC, ReactNode } from "react";
import StyledButton, { StyledButtonType } from "./StyledButton";

interface PositiveButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
}

const PositiveButton: FC<PositiveButtonProps> = ({ children, className, ...props }) => {
  return (
    <StyledButton buttonType={StyledButtonType.Positive} className={className} {...props}>
        {children}
    </StyledButton>
  );
};

export default PositiveButton;
