import React, { ButtonHTMLAttributes, FC, ReactNode } from "react";
import StyledButton, { StyledButtonType } from "./StyledButton";

interface NeutralButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
}

const NeutralButton: FC<NeutralButtonProps> = ({ children, className, ...props }) => {
  return (
    <StyledButton buttonType={StyledButtonType.Neutral} className={className} {...props}>
        {children}
    </StyledButton>
  );
};

export default NeutralButton;
