import { FormEvent, useEffect, useState } from "react";
import { useMe } from "../../context/MeContext";
import useExperienceService from "../../services/ExperienceService";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import PositiveButton from "../PositiveButton";
import useCompanyService from "../../services/CompanyService";

interface InviteToUserCompanyFormProps {
    companyId?:string;
    open:boolean,
    setOpen: (open: boolean) => void;
    onComplete?: (email:string) => void;
    onCancel?: () => void;
}

export default function InviteToUserCompanyForm(
    {companyId, open, setOpen, onComplete, onCancel}: InviteToUserCompanyFormProps
) {
    const { me } = useMe();

    const roleTypes = [
        {id:"Developer", name:"Developer", description:"Can perform all developer actions on experiences"},
        {id:"Manager", name:"Manager", description:"Can perform all managerial actions on an organisation"},
        {id:"Owner", name:"Owner", description:"Can perform all actions on an organisation"},
    ]

    const [formData, setFormData] = useState({
      email: "",
      role: "",
      companyId: companyId ?? me?.memberships[0].company?.id
    });
  
    const [submitted, setSubmitted] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
  
    const [validationErrors, setValidationErrors] = useState<{
      [key: string]: string;
    }>({});
  
    const { inviteUserToCompany } = useCompanyService();
  
    const validateForm = () => {
      let isValid = true;
      const newErrors: any = {};
  
      if (formData.email.trim() === "") {
        newErrors.email = "Email is required";
        isValid = false;
      }

      if (!isValidEmail(formData.email)) {
        newErrors.email = "Please enter a valid email address";
        isValid = false;
      }

      if (formData.role.trim() === "") {
        newErrors.role = "Please select a role";
        isValid = false;
      }
  
      if (formData.companyId?.trim() === "") {
        newErrors.companyId = "Company ID is required";
        isValid = false;
      }
  
      setValidationErrors(newErrors);
  
      return isValid;
    };
  
    const submitInviteUser = async (e: FormEvent) => {
      e.preventDefault();
  
      setSubmitted(true);
  
      if (!validateForm()) return;
  
      setIsSaving(true);
  
      try {
        await inviteUserToCompany(formData.companyId as string, formData.email, formData.role);
  
        if (onComplete) {
            onComplete(formData.email);
        }
  
        setFormData({
          email: "",
          role: "",
          companyId: me?.memberships[0].company?.id,
        });

        setSubmitted(false);
  
        if (!onComplete) {
          setOpen(false);
        }
        
      } catch (error) {
        const newErrors: any = {
          email: "Failed to invite user. Please try again. If the problem persists, please contact support.",
        };
        setValidationErrors(newErrors);
      } finally {
        setIsSaving(false);
      }
    };
  
    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
  
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const validStyle = (fieldName: string) => {
      // Find the validationErrors key with the fieldName.
      let valid = Object.keys(validationErrors).indexOf(fieldName) === -1;
  
      return valid ? "" : "border-red-800";
    };
  
    const validationError = (fieldName: string): null | string => {
      return validationErrors[fieldName] || null;
    };
  
    const isFormValid = submitted && Object.keys(validationErrors).length === 0;

    const isValidEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    useEffect(() => {
        if (submitted) {
          validateForm();
        }
    }, [submitted, formData]);
  
    return (
      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
  
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-slate-900 text-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <DialogTitle
                as="h3"
                className="text-base leading-6 text-white pb-4"
              >
                Invite user to organisation
              </DialogTitle>
              <form
                onSubmit={submitInviteUser}
                className="card p-8 flex flex-col form grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4"
              >
                <div className="sm:col-span-4">
                  <label htmlFor="name" className="block leading-6">
                    Email:
                  </label>
                  <div className="mt-2">
                    <input
                      disabled={isSaving}
                      type="text"
                      name="email"
                      placeholder="Users email *"
                      value={formData.email}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                        "email"
                      )}`}
                    />
                  </div>
                  {validationError("email") && (
                    <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                      {validationError("email")}
                    </div>
                  )}
                </div>
                {!companyId && <div className="sm:col-span-4">
                  <label htmlFor="companyId" className="block leading-6">
                    Company:
                  </label>
                  <div className="mt-2">
                  <select
                    name="companyId"
                    value={formData.companyId}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border rounded text-black"
                  >
                    <option value="">Please select</option>
                    {me?.memberships.map((membership) => <option key={membership.company?.id} value={membership.company?.id}>{membership.company?.name}</option>)}
                  </select>
                  </div>
                  {validationError("companyId") && (
                    <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                      {validationError("companyId")}
                    </div>
                  )}
                </div>}
                <div className="sm:col-span-4">
                  <label htmlFor="role" className="block leading-6">
                    Role:
                  </label>
                  <div className="mt-2">
                  <select
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border rounded text-black"
                  >
                    <option value="">Please select</option>
                    {roleTypes.map((roleType) => <option key={roleType.id} value={roleType.id}>{roleType.name}</option>)}
                  </select>
                  </div>
                  {validationError("role") && (
                    <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                      {validationError("role")}
                    </div>
                  )}
                </div>
                {/* Submit Button */}
                <div className="sm:col-span-4 flex items-center gap-2 justify-end">
                  <PositiveButton
                    type="submit"
                    disabled={isSaving}
                  >
                    {isSaving ? "Inviting..." : "Invite user"}
                  </PositiveButton>
                </div>
              </form>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    );
}