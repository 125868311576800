import { useEffect, useState } from "react";

export default function LoadingEllipsis() {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prevDots => (prevDots.length < 3 ? prevDots + '.' : ''));
        }, 500); // Change the speed of the animation here

        return () => clearInterval(interval);
    }, []);

    return (
        <span style={{ display: 'inline-block', width: '1.5ch' }}>{dots}</span>
    );
}