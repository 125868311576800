interface VideoListContainerProps {
  children: React.ReactNode;
}

export function VideoListContainer({ children }: VideoListContainerProps) {
  return (
    <section role="list" className="flex flex-col gap-2">
      <div className="py-2">
        <p className="mt-3">
          View recordings from your company&apos;s experiences by using the
          filter options below.
        </p>
        {children}
      </div>
    </section>
  );
}
