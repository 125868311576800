import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useCompanyService from "../../services/CompanyService";
import { Company } from "../../types/Company";
import PageTitle from "../../components/PageTitle";
import LoadingEllipsis from "../../components/LoadingEllipsis";
import Ghost from "../../components/Ghost";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import BackButton from "../../components/BackButton";
import PositiveButton from "../../components/PositiveButton";
import CompanyDetailForm from "../../components/Company/CompanyDetailForm";
import { User, UserAndRole } from "../../types/User";
import NegativeButton from "../../components/NegativeButton";
import { Experience } from "../../types/Experience";
import useExperienceService from "../../services/ExperienceService";
import NewExperienceForm from "../../components/Experiences/NewExperienceForm";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import InviteToUserCompanyForm from "../../components/User/InviteUserToCompanyForm";

export default function AdminCompanyDetailPage() {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const { getCompany, updateCompany, getCompanyUsers } = useCompanyService();
  const { getExperiencesForCompany } = useExperienceService();

  const [addExperienceFormOpen, setAddExperienceFormOpen] = useState(false);
  const [inviteUserFormOpen, setInviteUserFormOpen] = useState(false);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isAddingExperience, setIsAddingExperience] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const [company, setCompany] = useState<Company | null>(null);
  const [users, setUsers] = useState<UserAndRole[] | null>(null);
  const [experiences, setExperiences] = useState<Experience[] | null>(null);

  useEffect(() => {
    if (!initiated) {
      setIsLoading(true);
      setInitiated(true);
    }

    getCompany(companyId as string)
      .then((company) => {
        setCompany(company);
      })
      .catch((error) => {
        console.error("Error fetching company:", error);
      });

    getCompanyUsers(companyId as string)
      .then((users) => {
        setUsers(users);
      })
      .catch((error) => {
        console.error("Error fetching company users:", error);
      });

    getExperiencesForCompany(companyId as string)
      .then((experiences) => {
        setExperiences(experiences);
      })
      .catch((error) => {
        console.error("Error fetching company experiences:", error);
      });

  }, [initiated]);

  useEffect(() => {
    if (company && users && experiences) {
      setIsLoading(false);
    }
  }, [company, users]);

  const inviteUser = () => {
    console.log("Invite user");
  }

  const handleNewExperienceSaved = (experience: Experience) => {
    navigate(`/admin/experiences/${companyId}/${experience.id}`);
  }

  const handleUserInvited = (email: string) => {
    setInviteUserFormOpen(false);
    setIsLoading(true);
    setUsers(null);
    getCompanyUsers(companyId as string)
      .then((users) => {
        setUsers(users);
      })
      .catch((error) => {
        console.error("Error fetching company users:", error);
      });
  }

  return (
    <>
      <NewExperienceForm
        open={addExperienceFormOpen}
        setOpen={setAddExperienceFormOpen}
        companyId={companyId}
        onSaved={handleNewExperienceSaved} />
      <InviteToUserCompanyForm
        open={inviteUserFormOpen}
        setOpen={setInviteUserFormOpen}
        companyId={companyId}
        onComplete={handleUserInvited} />
      <section className="flex flex-col gap-2 divide-stone-900">
        <div className="flex flex-row">
          <BackButton />
          <PageTitle className="text-4xl">
            {company ? company.name : `Loading`}{" "}
            {!company && <LoadingEllipsis />}
          </PageTitle>
        </div>
        <div className="grid grid-cols-1 2xl:grid-cols-3 gap-4">
          <div className="flex flex-col gap-2 col-span-2">
            <h2 className="text-2xl">Details</h2>
            {company ? (
              <CompanyDetailForm company={company} />
            ) : (
              <Ghost additionalClasses="h-24 col-span-2 rounded" />
            )}
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl">Experiences</h2>
            {experiences ? (
              experiences.map((experience) => (
                <NavLink
                  to={`/admin/experiences/${experience.companyId}/${experience.id}`}
                  key={experience.id}
                  className="card p-4 flex flex-row justify-between items-center gap-2">
                  <div className="flex flex-col gap-2 grow">
                    <p>{experience.name}</p>
                  </div>
                  <ArrowForwardTwoToneIcon />
                </NavLink>
              ))
            ) : (
              <Ghost additionalClasses="h-24 rounded" />
            )}
            {experiences && experiences.length === 0 && <p>This company has no experiences</p>}
            {!isLoading && (
              <PositiveButton
                onClick={() => { setAddExperienceFormOpen(true); }}
                disabled={isLoading || isAddingExperience}
              >
                <AddTwoToneIcon className="h-8 w-8" />
                <span>
                  {isAddingExperience ? "Creating experience" : "Add new experience"}
                  {isAddingExperience && <LoadingEllipsis />}
                </span>
              </PositiveButton>
            )}
            <h2 className="text-2xl">Users</h2>
            {users ? (
              users.map((user) => (
                <NavLink
                  to={`/admin/users/${user.id}`}
                  key={user.id}
                  className="card p-4 flex flex-row justify-between items-center gap-2">
                  <div className="flex flex-col gap-2 grow">
                    <p>{user.email}</p>
                    <p>{user.nickname && <span>{`${user.nickname} `}</span>}{user.familyName}</p>
                  </div>
                  <ArrowForwardTwoToneIcon />
                </NavLink>
              ))
            ) : (
              <Ghost additionalClasses="h-24 rounded" />
            )}
            {users && users.length === 0 && <p>This company has no users</p>}
            {!isLoading && (
              <PositiveButton
              onClick={() => { setInviteUserFormOpen(true); }}
                disabled={isLoading || isAddingUser}
              >
                <AddTwoToneIcon className="h-8 w-8" />
                <span>
                  {isAddingUser ? "Inviting user" : "Invite user"}
                  {isAddingUser && <LoadingEllipsis />}
                </span>
              </PositiveButton>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
