import React, { ButtonHTMLAttributes, FC, ReactNode } from "react";
import StyledButton, { StyledButtonType } from "./StyledButton";

interface NegativeButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
}

const NegativeButton: FC<NegativeButtonProps> = ({ children, className, ...props }) => {
  return (
    <StyledButton buttonType={StyledButtonType.Negative} className={className} {...props}>
        {children}
    </StyledButton>
  );
};

export default NegativeButton;
