import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingEllipsis from "../../components/LoadingEllipsis";
import useExperienceService from "../../services/ExperienceService";
import { Experience } from "../../types/Experience";
import Ghost from "../../components/Ghost";
import { ExperienceKey } from "../../types/ExperienceKey";
import ExperienceDetailForm from "../../components/Experiences/ExperienceDetailForm";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import ExperienceKeyCard from "../../components/Experiences/ExperienceKeyCard";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import PageTitle from "../../components/PageTitle";
import PositiveButton from "../../components/PositiveButton";

export default function ExperienceDetailPage() {
  // Load the companyID and experienceID from the URL.
  // Use the useParams hook from react-router-dom to get the parameters.
  const { companyId, experienceId } = useParams();

  const { getExperience, getExperienceKeys, createNewExperienceKey } =
    useExperienceService();

  const [isLoading, setIsLoading] = useState(false);

  const [isAddingKey, setIsAddingKey] = useState(false);

  const [initiated, setInitiated] = useState(false);

  const [experience, setExperience] = useState<Experience | null>(null);

  const [experienceKeys, setExperienceKeys] = useState<ExperienceKey[] | null>(
    null
  );

  useEffect(() => {
    if (!initiated) {
      setIsLoading(true);
      setInitiated(true);
    }

    getExperience(companyId as string, experienceId as string)
      .then((experience) => {
        setExperience(experience);
      })
      .catch((error) => {
        console.error("Error fetching experience:", error);
      });

    getExperienceKeys(companyId as string, experienceId as string)
      .then((keys) => {
        setExperienceKeys(keys);
      })
      .catch((error) => {
        console.error("Error fetching experience keys:", error);
      });
  }, [initiated]);

  const handleDeleteKey = (key: ExperienceKey) => {
    const newKeys = experienceKeys?.filter((k) => k.key !== key.key) ?? [];

    setExperienceKeys(newKeys);
  };

  const addNewKey = () => {
    setIsAddingKey(true);

    createNewExperienceKey(companyId as string, experienceId as string)
      .then((key) => {
        setExperienceKeys([...(experienceKeys ?? []), key]);
      })
      .catch((error) => {
        console.error("Error adding new key:", error);
        alert("Failed to add new key. Please try again.");
      })
      .finally(() => {
        setIsAddingKey(false);
      });
  };

  useEffect(() => {
    const stillLoading = !experience || !experienceKeys;

    setIsLoading(stillLoading);
  }, [experience, experienceKeys]);

  return (
    <section className="flex flex-col gap-2 divide-stone-900">
      <div className="flex flex-row">
        <Link to="/experiences" className="absolute -ml-8 mt-1 text-2xl">
          <ArrowBackTwoToneIcon />
        </Link>
        <PageTitle className="text-4xl">
          {experience ? experience.name : `Loading`}{" "}
          {!experience && <LoadingEllipsis />}
        </PageTitle>
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
        <div className="flex flex-col gap-2 col-span-2">
          <h2 className="text-2xl">Details</h2>
          {experience ? (
            <ExperienceDetailForm experience={experience} />
          ) : (
            <Ghost additionalClasses="h-24 col-span-2 rounded" />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="text-2xl">Keys</h2>
          {experienceKeys ? (
            experienceKeys.map((key) => (
              <ExperienceKeyCard
                key={key.key}
                experienceKey={key}
                onDelete={handleDeleteKey}
                deleteDisabled={experienceKeys.length == 1}
                experienceId={experience!.id!}
                companyId={experience!.companyId}
              />
            ))
          ) : (
            <Ghost additionalClasses="h-24 rounded" />
          )}
          {!isLoading && (
            <PositiveButton
              onClick={addNewKey}
              disabled={isLoading || isAddingKey}
            >
              <AddTwoToneIcon className="h-8 w-8" />
              <span>
                {isAddingKey ? "Adding key" : "Add key"}
                {isAddingKey && <LoadingEllipsis />}
              </span>
            </PositiveButton>
          )}
        </div>
      </div>
    </section>
  );
}
