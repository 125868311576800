const useUrlUtilities = () => {
  const isValidUrl = (urlString: string):boolean => {
    let url;

    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  return {isValidUrl};
};

export default useUrlUtilities;
