import { useState, useEffect, useCallback, useRef } from "react";
import { SpeakerWaveIcon, SpeakerXMarkIcon } from "@heroicons/react/24/solid";
import LoopIcon from "@mui/icons-material/Loop";
import LoadingEllipsis from "../LoadingEllipsis";
import VideoToggleButton from "../VideoToggleButton";
import useRecordingService from "../../services/RecordingSerice";
import { Recording } from "../../types/Recording";
import { formatRelativeTime } from "../../utilities/DateUtilities";
import { SimpleUser } from "../../types/User";
import { useVideoSize } from "../../hooks/useVideoSize";
import BackButton from "../BackButton";

interface RecordingDetailsProps {
  recording: Recording & { user?: SimpleUser };
}

function RecordingDetails({ recording }: RecordingDetailsProps) {
  const { getWatchUrl } = useRecordingService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [watchUrl, setWatchUrl] = useState<string | null>(null);
  const [isVideoReady, setIsVideoReady] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const videoEl = useRef(null);
  const { videoSize, parentWidth, updateVideoSize } = useVideoSize(videoEl);
  const hasFetchedUrl = useRef(false);

  const formattedStarted = formatRelativeTime(recording.started);
  const userName = recording.user?.name || null;

  const loadWatchUrl = useCallback(
    async (id: string) => {
      if (!id || isLoading || hasFetchedUrl.current) return;

      setIsLoading(true);

      try {
        const watchUrl: string = await getWatchUrl(id);
        hasFetchedUrl.current = true;
        setWatchUrl(watchUrl);
      } catch (error) {
        console.error("Failed to load watch url:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [getWatchUrl, isLoading, hasFetchedUrl]
  );

  useEffect(() => {
    if (recording.id) {
      loadWatchUrl(recording.id);
    }
  }, [recording.id, loadWatchUrl]);

  useEffect(() => {
    hasFetchedUrl.current = false;
  }, [recording.id]);

  const handleCanPlay = () => {
    setIsVideoReady(true);
    updateVideoSize();
  };

  const handleToggleMute = () => {
    setIsMuted((prev) => !prev);
  };

  if (isLoading) {
    return (
      <h1 className="text-white text-3xl ">
        Loading Recording
        <LoadingEllipsis />
      </h1>
    );
  }

  return (
    <div className="px-2  relative">
      <BackButton />

      <h1 className="text-3xl mb-1">
        {userName ? `Recording by: ${userName}` : "Recording"}
      </h1>
      <p className="text-white">Recorded: {formattedStarted}</p>

      <div className="relative w-full rounded-2xl mt-8 flex justify-center max-h-[80vh]">
        {isVideoReady && (
          <VideoToggleButton
            isToggledOn={isMuted}
            toggleAction={handleToggleMute}
            iconOn={SpeakerXMarkIcon}
            iconOff={SpeakerWaveIcon}
            labelOn="Unmute"
            labelOff="Mute"
            videoSize={videoSize}
            parentWidth={parentWidth}
            horizontalPosition="left"
            verticalPosition="top"
            verticalOffset="18px"
          />
        )}

        {!isVideoReady && (
          <div className="flex items-center justify-center h-[33vh] bg-gray-30">
            <div className="flex flex-col items-center space-y-4">
              <h1 className="text-lg text-white">
                Preparing video
                <LoadingEllipsis />
              </h1>
              <LoopIcon
                className="animate-spin text-white"
                style={{ fontSize: 40 }}
              />
            </div>
          </div>
        )}

        {watchUrl && (
          <video
            ref={videoEl}
            src={watchUrl}
            muted={isMuted}
            autoPlay
            controls
            playsInline
            onCanPlay={handleCanPlay}
            className={`rounded-2xl w-full ${
              isVideoReady ? "block" : "hidden"
            }`}
            style={{
              maxHeight: "80vh",
              maxWidth: "100%",
            }}
          />
        )}
      </div>
    </div>
  );
}

export default RecordingDetails;
