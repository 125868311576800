import { HttpError } from "../types/Error";
import {
  Company,
  CompanySummary,
  UpdateCompanyDetails,
} from "../types/Company";
import useApiClient from "./ApiClient";
import { PagedResults } from "../types/PagedResults";
import { UserAndRole } from "../types/User";
import { useCallback } from "react";

const useCompanyService = () => {
  const { get, post, delete: del } = useApiClient();

  const getCompanyUsers = useCallback(
    async (companyId: string): Promise<UserAndRole[]> => {
      try {
        const users = await get<UserAndRole[]>(`/company/${companyId}/users`);

        // Check if the response is an error.
        if ((users as HttpError).errorCode) {
          throw (users as HttpError).description;
        }

        return users as UserAndRole[];
      } catch (error) {
        console.error("Error getting company users:", error);
        throw error;
      }
    },
    [get]
  );

  const registerCompany = async (name: string): Promise<Company> => {
    try {
      const company = await post<Company>("/company/register", {
        companyName: name,
      });
      // Check if the response is an error.
      if ((company as HttpError).errorCode) {
        throw (company as HttpError).description;
      }

      return company as Company;
    } catch (error) {
      console.error("Error registering company:", error);
      throw error;
    }
  };

  const searchCompanies = useCallback(
    async (
      searchTerm: string,
      page: number = 1,
      itemsPerPage: number = 50,
      orderBy: string = "createdAt",
      orderDirection: string = "desc"
    ): Promise<PagedResults<CompanySummary>> => {
      try {
        const results = await post<PagedResults<CompanySummary>>(
          "/admin/company/search",
          { searchTerm, page, itemsPerPage, orderBy, orderDirection }
        );
        // Check if the response is an error.
        if ((results as HttpError).errorCode) {
          throw (results as HttpError).description;
        }

        return results as PagedResults<CompanySummary>;
      } catch (error) {
        console.error("Error searching companies", error);
        throw error;
      }
    },
    [post]
  );

  const getCompany = async (companyId: string): Promise<Company> => {
    try {
      const company = await get<Company>(`/company/${companyId}`);
      // Check if the response is an error.
      if ((company as HttpError).errorCode) {
        throw (company as HttpError).description;
      }

      return company as Company;
    } catch (error) {
      console.error("Error getting company:", error);
      throw error;
    }
  };

  const getCompanySummaries = async (): Promise<CompanySummary[]> => {
    try {
      const companies = await get<CompanySummary[]>("/admin/company/all");
      // Check if the response is an error.
      if ((companies as HttpError).errorCode) {
        throw (companies as HttpError).description;
      }

      return companies as CompanySummary[];
    } catch (error) {
      console.error("Error getting company summaries:", error);
      throw error;
    }
  };

  const updateCompany = async (
    company: UpdateCompanyDetails
  ): Promise<Company> => {
    try {
      const updatedCompany = await post<Company>("/company/update", company);
      // Check if the response is an error.
      if ((updatedCompany as HttpError).errorCode) {
        throw (updatedCompany as HttpError).description;
      }

      return updatedCompany as Company;
    } catch (error) {
      console.error("Error saving company:", error);
      throw error;
    }
  };

  const inviteUserToCompany = async (
    companyId: string,
    email: string,
    role: string
  ): Promise<void> => {
    try {
      const result = await post("/company/InviteUser", {
        companyId,
        emailAddress: email,
        role,
      });
      // Check if the response is an error.
      if ((result as HttpError).errorCode) {
        throw (result as HttpError).description;
      }

      return;
    } catch (error) {
      console.error("Error inviting user:", error);
      throw error;
    }
  };

  const removeUserFromCompany = async (
    companyId: string,
    userId: string
  ): Promise<void> => {
    try {
      const result = await del(`/company/${companyId}/users/${userId}`);
      // Check if the response is an error.
      if ((result as HttpError).errorCode) {
        throw (result as HttpError).description;
      }

      return;
    } catch (error) {
      console.error("Error removing user:", error);
      throw error;
    }
  };

  const changeUserRoleAtCompany = async (
    companyId: string,
    userId: string,
    userEmail: string,
    role: string
  ): Promise<void> => {
    try {
      // Remove the user then re-add them with the new role.
      await removeUserFromCompany(companyId, userId);
      await inviteUserToCompany(companyId, userEmail, role);
    } catch (error) {
      console.error("Error changing user role:", error);
      throw error;
    }
  };

  return {
    registerCompany,
    searchCompanies,
    updateCompany,
    getCompany,
    getCompanySummaries,
    getCompanyUsers,
    inviteUserToCompany,
    removeUserFromCompany,
    changeUserRoleAtCompany,
  };
};

export default useCompanyService;
