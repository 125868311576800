// context/MeContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import useMeService from '../services/MeService';
import { Me } from '../types/Me';
import useApiClient from '../services/ApiClient';

interface MeContextType {
  me: Me | null;
  isLoading: boolean;
  isAdmin: boolean;
  loadMe: () => void;
  refreshMe: () => void; // New refresh method
}

const MeContext = createContext<MeContextType | undefined>(undefined);

export const useMe = (): MeContextType => {
  const context = useContext(MeContext);
  if (!context) {
    throw new Error('useMe must be used within a MeProvider');
  }
  return context;
};

export const MeProvider = ({ children }: { children: ReactNode }) => {
  const { getMe } = useMeService();
  const { refreshToken } = useApiClient(); // Use the API client to refresh the token when needed
  const [me, setMe] = useState<Me | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Function to load the Me object (used initially)
  const loadMe = async () => {
    if (me || isLoading) return; // Avoid reloading if already loaded or in the process of loading
    setIsLoading(true);
    try {
      const meData = await getMe();

      // Check if the response is an error
      if ((meData as any).errorCode) {
        console.error('Failed to load Me:', meData);
        return;
      }

      const newMe: Me = meData as Me;

      setMe(newMe);

      setIsAdmin(newMe.roles.includes('administrator'));

    } catch (error) {
      console.error('Failed to load Me:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to refresh the Me object and token
  const refreshMe = async () => {
    setIsLoading(true);
    try {
      await refreshToken(); // Refresh the access token to ensure it's up-to-date
      const refreshedMe = await getMe(); // Fetch updated user data after refreshing the token
      // Check if the response is an error
      if ((refreshedMe as any).errorCode) {
        console.error('Failed to load Me:', refreshedMe);
        return;
      }

      setMe(refreshedMe as Me);
    } catch (error) {
      console.error('Failed to refresh Me:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Automatically load Me when the provider is initialized if it's not already loaded
  useEffect(() => {
    if (!me) {
      loadMe();
    }
  }, [me]);

  return (
    <MeContext.Provider value={{ me, isLoading, loadMe, refreshMe, isAdmin }}>
      {children}
    </MeContext.Provider>
  );
};
