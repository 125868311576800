import { useState, useEffect, useRef } from "react";
import { CompanySummary } from "../types/Company";
import { useMe } from "../context/MeContext";
import useCompanyService from "../services/CompanyService";
import { ROLES } from "../constants/roles";

export function useCompanies() {
  const { me } = useMe();

  const { getCompanySummaries } = useCompanyService();
  const [companies, setCompanies] = useState<CompanySummary[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current || companies.length > 0) return;

    const fetchCompaniesForAdmin = async () => {
      setIsLoading(true);

      try {
        const adminCompanies = await getCompanySummaries();

        setCompanies(adminCompanies);
      } catch (error) {
        console.error("Error fetching admin companies:", error);
      } finally {
        setIsLoading(false);
        isInitialized.current = true;
      }
    };

    const fetchCompaniesForUser = () => {
      setIsLoading(true);
      if (me?.memberships) {
        const companiesList = me.memberships.map((membership) => ({
          id: membership.company.id,
          name: membership.company.name,
        }));
        setCompanies(companiesList);
      }
      setIsLoading(false);
      isInitialized.current = true;
    };

    const isAdmin = me?.roles.includes(ROLES.ADMIN);

    if (isAdmin) {
      fetchCompaniesForAdmin();
    } else {
      fetchCompaniesForUser();
    }
  }, [companies.length, getCompanySummaries, me?.memberships, me?.roles]);

  return { companies, isLoading };
}
