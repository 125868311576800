// Props for ExperienceDetailForm: experience, onSaved.

import { FormEvent, useState } from "react";
import { Experience } from "../../types/Experience";
import useUrlUtilities from "../../utilities/UrlUtilities";
import useExperienceService from "../../services/ExperienceService";
import SimpleAlert from "../SimpleAlert";
import { useNavigate } from "react-router-dom";
import LoadingEllipsis from "../LoadingEllipsis";
import PositiveButton from "../PositiveButton";
import NegativeButton from "../NegativeButton";

interface ExperienceDetailFormProps {
  experience: Experience;
  onSaved?: (experience: Experience) => void;
}

export default function ExperienceDetailForm({
  experience,
  onSaved,
}: ExperienceDetailFormProps) {
  const [formData, setFormData] = useState({
    name: experience.name,
    websiteUrl: experience.websiteUrl,
    shortDescription: experience.shortDescription,
    longDescription: experience.longDescription,
    searchKeywords: experience.searchKeywords,
    privacyPolicyUrl: experience.privacyPolicyUrl,
  });

  const { isValidUrl } = useUrlUtilities();
  const navigate = useNavigate();

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isClean, setIsClean] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const { updateExperience, deleteExperience } = useExperienceService();

  const deleteThisExperience = async () => {
    try {
      setIsDeleting(true);
      await deleteExperience(experience.companyId, experience.id);
      setDeleteAlertOpen(false);
      navigate(-1);
    } catch (error) {
      const newErrors: any = {
        name: "Failed to delete experience. Please try again. If the problem persists, please contact support.",
      };
      setValidationErrors(newErrors);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};
    
    if (formData.name.trim() === "") {
      newErrors.name = "Experience name is required";
      isValid = false;
    }

    if ((formData.websiteUrl ?? "").trim() !== "") {
      // Check the website url is a valid url using regex.
      if (!isValidUrl(formData.websiteUrl!.trim())) {
        newErrors.websiteUrl =
          "Url is not a valid url in the form https://company.com";
        isValid = false;
      }
    }

    if ((formData.privacyPolicyUrl ?? "").trim() !== "") {
      // Check the website url is a valid url using regex.
      if (!isValidUrl(formData.privacyPolicyUrl!.trim())) {
        newErrors.privacyPolicyUrl =
          "Url is not a valid url in the form https://company.com";
        isValid = false;
      }
    }

    // Short description is required.
    if (formData.shortDescription?.trim() === "") {
      newErrors.shortDescription = "Short description is required";
      isValid = false;
    }

    setValidationErrors(newErrors);

    return isValid;
  };

  const saveExperience = async (e: FormEvent) => {
    e.preventDefault();

    setSubmitted(true);

    if (!validateForm()) return;

    setIsSaving(true);

    try {
      // Save the experience
      const experienceToSave: Experience = {
        ...experience,
        ...formData,
      };

      const newExperience = await updateExperience(experienceToSave);

      if (onSaved) {
        onSaved(newExperience);
      }

      setIsClean(true);
    } catch (error) {
      const newErrors: any = {
        name: "Failed to save experience. Please try again. If the problem persists, please contact support.",
      };
      setValidationErrors(newErrors);
    } finally {
      setIsSaving(false);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setIsClean(false);

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validStyle = (fieldName: string) => {
    // Find the validationErrors key with the fieldName.
    let valid = Object.keys(validationErrors).indexOf(fieldName) === -1;

    return valid ? "" : "border-red-800";
  };

  const validationError = (fieldName: string): null | string => {
    return validationErrors[fieldName] || null;
  };

  const isFormValid = submitted && Object.keys(validationErrors).length === 0;

  if (isDeleting) {
    return (
      <div className="flex items-center bg-gray-30">
            <div className="flex flex-col items-center space-y-4">
                <h1 className="text-lg text-white">
                    Deleting
                    <LoadingEllipsis />
                </h1>
            </div>
        </div>
    )
  }

  return (
    <>
      <form
        onSubmit={saveExperience}
        className="card p-8 flex flex-col form grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4"
      >
        <div className="sm:col-span-4">
          <label htmlFor="name" className="block leading-6">
            Experience name:
          </label>
          <div className="mt-2">
            <input
              disabled={isSaving}
              type="text"
              name="name"
              placeholder="Experience name *"
              value={formData.name}
              onChange={handleInputChange}
              className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                "name"
              )}`}
            />
          </div>
          {validationError("name") && (
            <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
              {validationError("name")}
            </div>
          )}
        </div>

        {/* Website URL */}
        <div className="sm:col-span-4">
          <label htmlFor="websiteUrl" className="block leading-6">
            Website:
          </label>
          <div className="mt-2">
            <input
              disabled={isSaving}
              type="url"
              name="websiteUrl"
              placeholder="https://supergamestud.io"
              value={formData.websiteUrl ?? ""}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded text-black"
            />
          </div>
          {validationError("websiteUrl") && (
            <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
              {validationError("websiteUrl")}
            </div>
          )}
        </div>

        {/* Short description */}
        <div className="sm:col-span-4">
          <label htmlFor="shortDescription" className="block leading-6">
            Short description:
          </label>
          <div className="mt-2">
            <input
              type="text"
              disabled={isSaving}
              placeholder="Short description"
              name="shortDescription"
              value={formData.shortDescription ?? ""}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded text-black"
            />
          </div>
          {validationError("shortDescription") && (
            <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
              {validationError("shortDescription")}
            </div>
          )}
        </div>

        {/* Long description */}
        <div className="sm:col-span-4">
          <label htmlFor="longDescription" className="block leading-6">
            Full description:
          </label>
          <div className="mt-2">
            <textarea
              disabled={isSaving}
              placeholder="Full description"
              name="longDescription"
              value={formData.longDescription ?? ""}
              rows={4}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded text-black"
            />
          </div>
          {validationError("longDescription") && (
            <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
              {validationError("longDescription")}
            </div>
          )}
        </div>

        {/* Privacy Policy URL */}
        <div className="sm:col-span-4">
          <label htmlFor="privacyPolicyUrl" className="block leading-6">
            Privacy policy url:
          </label>
          <div className="mt-2">
            <input
              disabled={isSaving}
              type="url"
              name="privacyPolicyUrl"
              placeholder="https://supergamestud.io"
              value={formData.privacyPolicyUrl ?? ""}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded text-black"
            />
          </div>
          {validationError("privacyPolicyUrl") && (
            <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
              {validationError("privacyPolicyUrl")}
            </div>
          )}
        </div>
        {/* Submit Button */}
        <div className="sm:col-span-4 flex items-center gap-2 justify-end">
          <NegativeButton
            type="button"
            disabled={isSaving}
            className={`text-red-500 py-2 px-4 rounded border-red-900 border hover:bg-red-900/25 `}
            onClick={() => setDeleteAlertOpen(true)}
          >
            Delete experience
          </NegativeButton>
          <PositiveButton
            type="submit"
            disabled={isSaving || isClean}
          >
            {isSaving ? "Saving..." : isClean ? "Saved" : "Save changes"}
          </PositiveButton>
        </div>
      </form>
      <SimpleAlert
        open={deleteAlertOpen}
        setOpen={setDeleteAlertOpen}
        title={`Are you sure you want to delete ${experience.name}?`}
        message="This action cannot be undone and any experiences using one of the experience keys will stop working."
        confirmText={`Delete ${experience.name}`}
        onConfirm={deleteThisExperience}
      />
    </>
  );
}
