import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import KeyboardArrowUpTwoToneIcon from "@mui/icons-material/KeyboardArrowUpTwoTone";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";

export interface NavigationItemDetails {
  name: string;
  href: string;
  icon: any;
  children?: NavigationItemDetails[];
}

interface NavigationItemProps {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  text: string;
  to: string;
  children?: NavigationItemDetails[];
  className?: string;
}

export default function NavigationItem({
  icon: Icon,
  text,
  to,
  children,
  className
}: NavigationItemProps) {
  const hasChildren = children !== undefined && children.length > 0;

  const [open, setOpen] = useState(false);

  const location = useLocation();

  const navButtonStyle = "p-3 text-slate-100 hover:text-sky-500 text-base md:text-lg flex flex-row justify-between gap-2 hover-grow ${className}";

  useEffect(() => {
    
    // Check to see if the location matches any of the children and if so then set open to true.
    if (children) {
      const found = children.find((child) => location.pathname.startsWith(child.href));
      if (found) {
        setOpen(true);
      }
    }

  }, [location]);


  return (
    <>
      <NavLink
        to={to}
        onClick={(e) => {
          if (hasChildren) {
            setOpen(!open);
          }
        }}
        className={({ isActive }) =>
          isActive
            ? `${navButtonStyle} text-sky-200`
            : `${navButtonStyle} text-slate-100`
        }
      >
        <span className="hidden xl:inline">
          <Icon />
        </span>
        <span className="flex-1">{text}</span>
        {hasChildren &&
          (open ? (
            <KeyboardArrowUpTwoToneIcon className="mt-1" />
          ) : (
            <ExpandMoreTwoToneIcon className="mt-1" />
          ))}
      </NavLink>
      {hasChildren && open && (
        <div className="flex flex-col gap-2 pl-4">
          {children?.map((child) => (
            <NavigationItem
              key={child.name}
              icon={child.icon}
              text={child.name}
              to={child.href}
              children={child.children}
            />
          ))}
        </div>
      )}
    </>
  );
}
