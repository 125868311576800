import { FormEvent, useState } from "react";
import { Experience } from "../../types/Experience";
import useExperienceService from "../../services/ExperienceService";
import { useMe } from "../../context/MeContext";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import PositiveButton from "../PositiveButton";

interface NewExperienceFormProps {
  companyId?:string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSaved?: (experience: Experience) => void;
  onCancel?: () => void;
}

export default function NewExperienceForm({
  onSaved,
  open,
  setOpen,
  onCancel,
  companyId
}: NewExperienceFormProps) {
  const { me } = useMe();

  const [formData, setFormData] = useState({
    name: "",
    companyId: companyId ?? me?.memberships[0].company?.id
  });

  const [submitted, setSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const { createExperience } = useExperienceService();

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};

    if (formData.name.trim() === "") {
      newErrors.name = "Experience name is required";
      isValid = false;
    }

    if (formData.companyId?.trim() === "") {
      newErrors.companyId = "Company ID is required";
      isValid = false;
    }

    setValidationErrors(newErrors);

    return isValid;
  };

  const saveExperience = async (e: FormEvent) => {
    e.preventDefault();

    setSubmitted(true);

    if (!validateForm()) return;

    setIsSaving(true);

    try {
      const newExperience = await createExperience(
        formData.companyId!,
        formData.name
      );

      if (onSaved) {
        onSaved(newExperience);
      }

      setFormData({
        name: "",
        companyId: me?.memberships[0].company?.id,
      });

      if (!onSaved) {
        setOpen(false);
      }
      
    } catch (error) {
      const newErrors: any = {
        name: "Failed to save experience. Please try again. If the problem persists, please contact support.",
      };
      setValidationErrors(newErrors);
    } finally {
      setIsSaving(false);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validStyle = (fieldName: string) => {
    // Find the validationErrors key with the fieldName.
    let valid = Object.keys(validationErrors).indexOf(fieldName) === -1;

    return valid ? "" : "border-red-800";
  };

  const validationError = (fieldName: string): null | string => {
    return validationErrors[fieldName] || null;
  };

  const isFormValid = submitted && Object.keys(validationErrors).length === 0;

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-slate-900 text-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <DialogTitle
              as="h3"
              className="text-base leading-6 text-white pb-4"
            >
              Create a new experience
            </DialogTitle>
            <form
              onSubmit={saveExperience}
              className="card p-8 flex flex-col form grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4"
            >
              {!companyId && <div className="sm:col-span-4">
                <label htmlFor="name" className="block leading-6">
                  Company:
                </label>
                <div className="mt-2">
                <select
                  name="companyId"
                  value={formData.companyId}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded text-black"
                >
                  <option value="">Please select</option>
                  {me?.memberships.map((membership) => <option key={membership.company?.id} value={membership.company?.id}>{membership.company?.name}</option>)}
                </select>
                </div>
                {validationError("companyId") && (
                  <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                    {validationError("companyId")}
                  </div>
                )}
              </div>}
              <div className="sm:col-span-4">
                <label htmlFor="name" className="block leading-6">
                  Experience name:
                </label>
                <div className="mt-2">
                  <input
                    disabled={isSaving}
                    type="text"
                    name="name"
                    placeholder="Experience name *"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded text-black ${validStyle(
                      "name"
                    )}`}
                  />
                </div>
                {validationError("name") && (
                  <div className="text-white bg-red-800 py-1 px-2 rounded-xl mt-2">
                    {validationError("name")}
                  </div>
                )}
              </div>
              {/* Submit Button */}
              <div className="sm:col-span-4 flex items-center gap-2 justify-end">
                <PositiveButton
                  type="submit"
                  disabled={isSaving}
                >
                  {isSaving ? "Creating..." : "Create experience"}
                </PositiveButton>
              </div>
            </form>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
