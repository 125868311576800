import { useState } from "react";
import ContentPasteTwoToneIcon from '@mui/icons-material/ContentPasteTwoTone';
import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';

interface CopySecretBoxProps {
    text: string;
    additionalClasses?: string;
    textBoxClasses?: string;
}

// Component which allows the contents of the box to be copied to the clipboard but also replaces the text with circles when hidden.
export default function CopySecretBox({text, additionalClasses, textBoxClasses}:CopySecretBoxProps) {
    const [copied, setCopied] = useState(false);
    const [hidden, setHidden] = useState(true);

    const textLength = text.length;

    const hiddenText = "•".repeat(textLength);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    const toggleHidden = () => {
        setHidden(!hidden);
    }

    return (
        <div className={`flex flex-row gap-2 ${additionalClasses}`}>
            <p className={`grow ${textBoxClasses}`}>{!hidden && text}{hidden && hiddenText}</p>
            <button onClick={toggleHidden} className="btn btn-primary" title={hidden ? "Show" : "Hide"}>
                {hidden ? <VisibilityTwoToneIcon className="h-8 w-8" /> : <VisibilityOffTwoToneIcon className="h-8 w-8" />}
            </button>
            <button onClick={copyToClipboard} className="btn btn-primary" title={copied ? "Copied to clipboard" : "Copy to clipboard"}>
                {copied ? <AssignmentTurnedInTwoToneIcon className="h-8 w-8" /> : <ContentPasteTwoToneIcon className="h-8 w-8" />}
            </button>
        </div>
    )
}