import { Link } from "react-router-dom";
import { Experience } from "../../types/Experience";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";

interface ExperienceListItemProps {
  experience: Experience;
}

export default function ExperienceListItem({
  experience,
}: ExperienceListItemProps) {
  const formattedDate = new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "long",
    day: "numeric"
  }).format(new Date(experience.createdAt));

  return (
    <Link
      to={`/experiences/${experience.companyId}/${experience.id}`}
      className={`card flex flex-col md:grid-cols-10 gap-4 p-4 justify-between`}
    >
      <div className="flex flex-row gap-2 align-middle">
        <h3 className="text-xl grow">{experience.name}</h3>
        <p className="text-sm pt-2">{formattedDate}</p>
      </div>
      
      <p>{experience.shortDescription}</p>

      {experience.isBlocked && (
        <>
        <p className="text-red-500">
          This experience is currently blocked meaning no new streaming sessions will be granted. 
          </p>
        <p className="text-red-500">
          
          {experience.blockedReason}
        </p>
        </>)}

      <div className="flex flex-row gap-2">
        <p className="grow">
          {experience.websiteUrl && (
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="underline"
              target="_blank"
              href={experience.websiteUrl}
            >
              {experience.websiteUrl}
            </a>
          )}
        </p>
        <p>{experience.searchKeywords}</p>
        <ArrowForwardTwoToneIcon />
      </div>
    </Link>
  );
}
