import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import LoadingEllipsis from "../../components/LoadingEllipsis";
import Ghost from "../../components/Ghost";
import BackButton from "../../components/BackButton";
import { User } from "../../types/User";
import { Experience } from "../../types/Experience";
import useUserService from "../../services/UserService";
import UserMembershipCard from "../../components/User/UserMembershipCard";
import UserDetailForm from "../../components/User/UserDetailForm";

export default function AdminUserDetailPage() {
  const { userId } = useParams();
  const { getUser } = useUserService();
  const [isLoading, setIsLoading] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (!initiated) {
      setIsLoading(true);
      setInitiated(true);
    }

    getUser(userId as string)
      .then((user) => {
        setUser(user);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      });

  }, [initiated]);

  useEffect(() => {
    if (user) {
      setIsLoading(false);
    }
  }, [user]);

  const handleUserSaved = (user: User) => {
    
  }

  return (
    <>
      <section className="flex flex-col gap-2 divide-stone-900">
        <div className="flex flex-row">
          <BackButton />
          <PageTitle className="text-4xl">
            {user ? user.email : `Loading`}{" "}
            {!user && <LoadingEllipsis />}
          </PageTitle>
        </div>
        <div className="grid grid-cols-1 2xl:grid-cols-3 gap-4">
          <div className="flex flex-col gap-2 col-span-2">
            <h2 className="text-2xl">Details</h2>
            {user ? (
              <UserDetailForm user={user} onSaved={handleUserSaved} />
            ) : (
              <Ghost additionalClasses="h-24 col-span-2 rounded" />
            )}
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl">Roles</h2>
            {user ? (
              user.roles.map((role) => (
                <div
                  key={role}
                  className="card p-4 flex flex-row justify-between items-center gap-2">
                  <div className="flex flex-col gap-2 grow">
                    <p>{role}</p>
                  </div>
                </div>
              ))
            ) : (
              <Ghost additionalClasses="h-24 rounded" />
            )}
            <h2 className="text-2xl">Memberships</h2>
            {user ? (
              user.memberships?.map((membership, idx) => (
                <UserMembershipCard userEmail={user.email} userId={user.id} key={idx} membership={membership} />
              ))
            ) : (
              <Ghost additionalClasses="h-24 rounded" />
            )}
            {user && user.memberships?.length === 0 && <p>This user has no memberships</p>}
          </div>
        </div>
      </section>
    </>
  );
}
