import React, { ButtonHTMLAttributes, FC, ReactNode } from "react";

export enum StyledButtonType {
  Negative = "negative",
  Positive = "positive",
  Neutral = "neutral",
}

interface StyledButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  buttonType?: StyledButtonType; // New prop to accept the button type
}

const StyledButton: FC<StyledButtonProps> = ({ children, className = "", buttonType = StyledButtonType.Neutral, ...props }) => {
  // Define the styles for each button type
  const getButtonClasses = (type: StyledButtonType) => {
    switch (type) {
      case StyledButtonType.Positive:
        return "px-4 py-2 rounded border enabled:text-cyan-300 enabled:border-cyan-300 enabled:hover:bg-cyan-300/25";
      case StyledButtonType.Negative:
        return "px-4 py-2 rounded border enabled:text-red-500 enabled:border-red-500 enabled:hover:bg-red-500/25";
      case StyledButtonType.Neutral:
      default:
        return "px-4 py-2 rounded border enabled:text-white enabled:hover:text-stone-950 enabled:border-stone-500 enabled:bg-stone-200/50 enabled:hover:bg-stone-200/75";
    }
  };

  const buttonClasses = getButtonClasses(buttonType);

  return (
    <button
      className={`${buttonClasses} disabled:text-gray-900 disabled:border-gray-900 disabled:bg-gray-300/25 ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default StyledButton;
