import { ReactNode, useEffect, useState } from "react";
import { Experience } from "../../types/Experience";
import NegativeButton from "../NegativeButton";
import PositiveButton from "../PositiveButton";
import PlayCircleFilledTwoToneIcon from "@mui/icons-material/PlayCircleFilledTwoTone";
import StopCircleTwoToneIcon from "@mui/icons-material/StopCircleTwoTone";
import BlockExperienceDialog from "./BlockExperienceDialog";
import useExperienceService from "../../services/ExperienceService";
import LoadingEllipsis from "../LoadingEllipsis";

interface ExperienceBlockCardProps {
  experience: Experience;
}

export default function ExperienceBlockCard({
  experience,
}: ExperienceBlockCardProps) {
  const [experienceModel, setExperienceModel] =
    useState<Experience>(experience);
  const [title, setTitle] = useState<string>("Experience is active");
  const [blockDialogOpen, setBlockDialogOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<ReactNode[]>(
    [<p>There is no block on the experience and new sessions can be freely created.</p>]
  );
  const [isUnblocking, setIsUnblocking] = useState<boolean>(false);
  const {unblockExperience} = useExperienceService();
  
  useEffect(() => {
    if (experienceModel.isBlocked) {
      setTitle("Experience Blocked");
      setMessage(
        [<p>This experience has been blocked by the administrator and new sessions cannot be created.</p>,
            <p className="text-red-500 text"><span className="text-xs">Reason: </span><span>{experienceModel.blockedReason}</span></p>
        ]
      );
    } else {
      setTitle("Experience is active");
      setMessage(
        [<p>There is no block on the experience and new sessions can be freely created.</p>]
      );
    }
  }, [experienceModel.isBlocked]);

  const handleExperiencedBlocked = (experience: Experience) => {
    setExperienceModel(experience);
    setIsUnblocking(false);
    setBlockDialogOpen(false);
  };

  const handleUnblockExperienceClick = () => {
    setIsUnblocking(true);
    unblockExperience(experienceModel.id).then((result) => {
        setIsUnblocking(false);
        setExperienceModel({...experienceModel, isBlocked: false});
    }).catch((error) => {
        console.error("Error unblocking experience:", error);
    }).finally(() => {
        setIsUnblocking(false);
    });
  }

  return (
    <>
      <div className="card p-4 flex flex-col gap-2">
        <h3 className="text-xl">{title}</h3>
        <p>{message}</p>
        {experienceModel.isBlocked && (
          <PositiveButton
            className="flex flex-row gap-2 text-center justify-center"
            onClick={handleUnblockExperienceClick}
            disabled={isUnblocking}
          >
            <PlayCircleFilledTwoToneIcon />
            {isUnblocking ? <><span>Unblocking</span><LoadingEllipsis /></> : "Unblock experience"}
          </PositiveButton>
        )}
        {!experienceModel.isBlocked && (
          <NegativeButton
            className="flex flex-row gap-2 text-center justify-center"
            onClick={() => {setBlockDialogOpen(true)}}
          >
            <StopCircleTwoToneIcon />
            Block experience
          </NegativeButton>
        )}
      </div>
      <BlockExperienceDialog
        experience={experienceModel}
        open={blockDialogOpen}
        setOpen={setBlockDialogOpen}
        onComplete={handleExperiencedBlocked}
      />
    </>
  );
}
