import {Me} from "../types/Me";
import { HttpError } from "../types/Error";
import useApiClient from "./ApiClient";
import { UpdatedUser, UserSummary } from "../types/User";

const useMeService = () => {
  const { get, put } = useApiClient();

  const getMe = async (): Promise<Me> => {
    try {
      const me = await get<Me>('/me');

      // Check if the response is an error.
      if ((me as HttpError).errorCode) {
        throw (me as HttpError).description;
      }

      return me as Me;
    } catch (error) {
      console.error('Error fetching Me object:', error);
      throw error;
    }
  };

  const updateMe = async (user: UpdatedUser): Promise<UserSummary> => {
    try {
        const result = await put<UserSummary>(`/me`, user);
        // Check if the response is an error.
        if ((result as HttpError).errorCode) {
            throw (result as HttpError).description;
        }
        
        return result as UserSummary;
    } catch (error) {
        console.error('Error updating user:', error);
        throw error;
    }
}

  return { getMe, updateMe };
};

export default useMeService;